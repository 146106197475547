import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.jpg";
import api from "services/api";
import { useParams, useNavigate } from "react-router-dom";
import { useToaster } from "hooks";

export const ConfirmPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { setSuccessToaster, setErrorToaster } = useToaster();

  const validationSchema = Yup.object({
    password: Yup.string()
    .required("Password is Required")
    .min(8, 'Password must be at least 8 characters')
    .max(30, 'Password must be at most 30 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must be match")
      .required("Required"),
    // agree: Yup.boolean()
    //   .oneOf([true], "Must agree to terms and conditions")
    //   .required("Required"),
  });

  const handleSubmit = async (values) => {
    console.log("Submitting values:", values); // For testing purposes
    api
      .post(`/set-password/${params.id}`, values)
      .then((res) => {
        setSuccessToaster("Password reset successfully");
        navigate("/");
      })
      .catch((err) => {
        setErrorToaster("Password reset failed");
      });
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4" style={{ maxWidth: "500px", width: "100%" }}>
        <div className="text-center">
          <img src={Logo} width="80px" alt="Logo" />
        </div>
        <div className="text-center mt-3">
          <h2>Set Password</h2>
        </div>
        <Formik
          initialValues={{ password: "", password_confirmation: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <div className="mb-3 position-relative">
                <label for="password" className="form-label">
                  Password
                </label>
                <Field
                  name="password"
                  type="password"
                  className="form-control"
                  id="password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3 position-relative">
                <label for="password" className="form-label">
                  Confirm Password
                </label>
                <Field
                  name="password_confirmation"
                  type="password"
                  className="form-control"
                  aria-describedby="password"
                />
                <ErrorMessage
                  name="password_confirmation"
                  component="div"
                  className="text-danger"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={isSubmitting}
              >
                Submit
              </button>
              {errors.email && (
                <div className="mt-3 text-danger text-center">
                  {errors.email}
                </div>
              )}
            </Form>
          )}
        </Formik>
        <div className="text-center mt-3">
          <p>
            I remembered my password. <Link to="/">Back to Login</Link>
          </p>
        </div>
        <div className="text-center">
          <p>
            Don't have a Zenosure account yet?{" "}
            <Link to="/register">Register Now</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
