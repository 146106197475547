import React, { useState, useEffect } from "react";
import api from "services/api";
import QRCode from 'qrcode.react';
import { useLocation} from "react-router-dom"

// import { useHistory } from 'react-router-dom';
const CheckMembership = () => {
  const [membershipId,setMembershipId] = useState("")
  const [membershipDetails,setMembershipDetails] = useState([])
  const [noData,setNoData] = useState(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('membershipid');
  console.log(id)
  const fetchMembershipData = async () => {
    setNoData(false)
    try {
      const response = await api.get(`/membership-application?applicationNo=${membershipId || id}`);
      // setMemberships(response.data.data.memberApplication);
      console.log(
        "membershipPersonDetails:",
        response.data.data.memberApplication
      );
      if(response?.data?.data?.memberApplication?.length === 1){
        setMembershipDetails( response.data.data.memberApplication)
      }else{
        setNoData(true)
      }
      
    } catch (error) {
      console.log("membershipPersonDetails error:", error);
    }
  };

  useEffect(()=>{
    if(id){
      fetchMembershipData()
    }
  },[id])


  return (
    <div className="container mx-5 mt-2">
     {!id && <div className="card mb-4">
        <h5 className="card-header">Please Enter The Membership Number</h5>
        <div className="card-body">
          <div className="d-flex align-items-start align-items-sm-center gap-4">
            <div className="d-flex" style={{ width: "400px" }}>
              <input
                type="text"
                className={`form-control`}
                placeholder="Membership Number"
                autofocus
                onChange={(e)=>setMembershipId(e.target.value)}
              />

              <button className="btn btn-primary ms-4" type="button" disabled={!membershipId}  onClick={fetchMembershipData}>
                <span className="d-none d-sm-block">Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>}
      {
        membershipDetails.length === 1 && (
          <div class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5 mb-1 ">
                <div class="customer-avatar-section">
                  <img
                    class="img-fluid rounded my-3"
                    src="../../assets/img/avatars/15.png"
                    style={{ width: "120px", height: "120px" }}
                    alt="User avatar"
                  />
                  <div class="customer-info ">
                    <h4 class="mb-1">Lorine Hischke</h4>
                  </div>
                </div>
                <div class="info-container">
                  <ul class="list-unstyled mt-3">
                    <li class="mb-2">
                      <span class="fw-medium me-2">Username:</span>
                      <span>lorine.hischke</span>
                    </li>
                    <li class="mb-2">
                      <span class="fw-medium me-2">Email:</span>
                      <span>vafgot@vultukir.org</span>
                    </li>
                    <li class="mb-2">
                      <span class="fw-medium me-2">Status:</span>
                      <span class="badge bg-label-success">Active</span>
                    </li>
                    <li class="mb-2">
                      <span class="fw-medium me-2">Contact:</span>
                      <span>(123) 456-7890</span>
                    </li>
  
                    <li class="mb-2">
                      <span class="fw-medium me-2">Country:</span>
                      <span>USA</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-7 mb-1 ">
                <div className="d-flex align-items-end">
                  <div className="col-md-9">
                  
                
                     <QRCode value={`https://zenosure.itprior.com/checkMembership?membershipid=${membershipDetails[0].application_no}`} size={150} />
                    
                    <div className="text-left">
                      <div class="mb-3">
                        <h6 class="mb-1">Your Current Plan is Basic</h6>
                        <p>A simple start for everyone</p>
                      </div>
                      <div class="mb-3">
                        <h6 class="mb-1">Active until Dec 09, 2021</h6>
                        <p>
                          We will send you a notification upon Subscription
                          expiration
                        </p>
                      </div>
                      <div class="mb-3">
                        <h6 class="mb-1">
                          <span class="me-2">$199 Per Month</span>
                          <span class="badge bg-label-primary">Popular</span>
                        </h6>
                        <p>Standard plan for small to medium businesses</p>
                      </div>
                    </div>
                    {/* <button
                      class="btn btn-primary me-2 mt-2"
                      data-bs-toggle="modal"
                      data-bs-target="#pricingModal"
                    >
                      Upgrade Plan
                    </button>
                    <button class="btn btn-label-danger cancel-subscription mt-2">
                      Cancel
                    </button> */}
                  </div>
                  <div class="card-body pb-0 px-0 px-md-4 col-md-3">
                    <img
                      src="../../assets/img/illustrations/card-advance-sale.png"
                      height="140"
                      alt="view sales"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }

      {
        noData && (
          <div class="card mb-4">
          <div class="card-body">
            <h1>No Membership Found Please Enter Valid Memberhip Id</h1>
            </div>
            </div>
        )
      }
     
    </div>
  );
};

export default CheckMembership;
