export const BASE_URL = "https://zenosure-api.itprior.com/api";

export const LOGIN = "";

export const REGISTER = "/register";

export const RELATIONS_ENDPOINT = "/relations";

export const DOCUMENT_SELECT = "/proof-of-identify";

export const KYC = "/kyc";

export const Privacy_Terms = "site-settings";

export const MEMBERSHIPALL = "membership-plans";