import React, { useEffect, useState, useRef } from "react";
import { useAuth, useToaster } from "hooks";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  addRoles,
  addAreas,
  addCountries,
} from "../../../redux/slices/profile_slice";
import api from "services/api";

const initialValues = {
  bc_first_name: "",
  bc_last_name: "",
  bc_email: "",
  bi_address: "",
  bi_city: "",
  bi_postal_code: "",
  bi_state: "",
  bi_country_id: null,
  bc_phone_no: null, // Initialize country as null or any default value
  bc_position: "",
  occupation_role_id: null,
  bi_company: "",
  function_id: null,
};

const BillingDetails = (props) => {
  const { setActiveStep, activeMembership, membershipId } = props;
  const modelButtonRef = useRef();

  const dispatch = useDispatch();
  const { user } = useAuth();
  const [billingInfo, setBillingInfo] = useState("company");
  const [addressType, setAddressType] = useState("");
  const { setSuccessToaster, setErrorToaster } = useToaster();
  const validationSchema = Yup.object().shape({
    bc_first_name: Yup.string()
      .required("First name is required")
      .min(3, "First name must be at least 3 characters"),
    bc_last_name: Yup.string()
      .required("Last name is required")
      .min(3, "Last name must be at least 3 characters"),
    bc_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    bi_company: Yup.string().required("Company is required"),
    bc_phone_no: Yup.string().required("Phone is required"),
    occupation_role_id: Yup.string().required("Role is required"),
    function_id: Yup.number().required("Function  is required"),
    bc_position: Yup.string().required("Position is required"),
    bi_country_id: Yup.number().required("Country  is required"),
    bi_city: Yup.string()
      .required("City is required")
      .min(3, "City must be at least 3 characters"),
    bi_state: Yup.string()
      .required("State is required")
      .min(3, "State must be at least 3 characters"),
    bi_postal_code: Yup.string()
      .required("Postal code is required")
      .min(3, "Postal code must be at least 3 characters"),
    bi_address: Yup.string()
      .required("Address is required")
      .min(10, "Address must be at least 10 characters"),
  });

  const { roles, areas, countries } = useSelector((state) => state?.profile);

  const handleSubmit = (values) => {
    modelButtonRef.current.click();
    // let payload = {
    //   ...values,
    //   member_app_id: membershipId,
    // };
    // api
    //   .post("/billing-details", payload)
    //   .then((res) => {
    //     modelButtonRef.current.click();
    //   })
    //   .catch((err) => {
    //     setErrorToaster("Billing Details Updating Failed");
    //   });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (roles?.length === 0) {
          await api.get("/occupation-roles").then((res) => {
            let rolesList = res?.data?.data?.roles || [];
            if (rolesList?.length > 0) {
              dispatch(addRoles(rolesList));
            }
          });
        }
        if (areas?.length === 0) {
          await api.get("/areas").then((res) => {
            let areasList = res?.data?.data?.areas || [];
            if (areasList?.length > 0) {
              dispatch(addAreas(areasList));
            }
          });
        }
        if (countries?.length === 0) {
          await api.get("/countries").then((res) => {
            let countriesList = res?.data?.data?.countries || [];
            if (countriesList?.length > 0) {
              dispatch(addCountries(countriesList));
            }
          });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    fetchData();
  }, []);

  const handleBillingInfo = (value, setValues, formValues) => {
    setBillingInfo(value);
    if (value === "personal") {
      api.get(`/users/${user?.user_id}`).then((res) => {
        const data = res?.data?.data?.user?.profile;
        if (data?.address) {
          setValues({
            ...formValues,
            bi_address: data?.address,
            bi_city: data?.city,
            bi_postal_code: data?.postal_code,
            bi_state: data?.state,
            bi_country_id: data?.country_id,
            bc_phone_no: data?.phone_no, // Initialize country as null or any default value
            bi_company: data?.company,
          });
        } else {
          setErrorToaster("No Information Availble");
        }
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ setFieldValue, errors, values, setValues }) => (
        <Form>
          <div class="card mb-4">
            <h5 class="card-header">Contact Details</h5>
            <div class="card-body">
              <div class="row">
                <div class="mb-3 col-sm-6">
                  <label for="companyName" class="form-label">
                    Email *
                  </label>
                  <Field
                    class="form-control"
                    type="text"
                    name="bc_email"
                    label="Email *"
                    variant="outlined"
                  />
                  <ErrorMessage
                    name="bc_email"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label for="billingEmail" class="form-label">
                    First Name *
                  </label>
                  <Field
                    class="form-control"
                    type="text"
                    name="bc_first_name"
                    label="First Name *"
                    variant="outlined"
                  />
                  <ErrorMessage
                    name="bc_first_name"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label for="taxId" class="form-label">
                    Last Name *
                  </label>
                  <Field
                    type="text"
                    id="taxId"
                    name="bc_last_name"
                    class="form-control"
                  />
                  <ErrorMessage
                    name="bc_last_name"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label for="vatNumber" class="form-label">
                    Phone *
                  </label>
                  <Field
                    class="form-control"
                    type="text"
                    name="bc_phone_no"
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue(
                        "bc_phone_no",
                        e.target.value.replace(/[^0-9]/g, "")
                      );
                    }}
                  />
                  <ErrorMessage
                    name="bc_phone_no"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label for="mobileNumber" class="form-label">
                    Title/Position *
                  </label>
                  <div class="input-group input-group-merge">
                    <Field
                      class="form-control mobile-number"
                      type="text"
                      name="bc_position"
                      label="Title/Position *"
                    />
                  </div>
                  <ErrorMessage
                    name="bc_position"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="country">
                    Function *
                  </label>
                  <select
                    class="select2 form-select"
                    value={values?.function_id}
                    onChange={(e) => {
                      setFieldValue("function_id", e.target.value || null);
                    }}
                  >
                    <option value="">Select</option>
                    {areas.map((area) => {
                      return <option value={area?.id}>{area?.name}</option>;
                    })}
                  </select>
                  <ErrorMessage
                    name="function_id"
                    component="div"
                    className="invalid-form"
                  />
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label" for="country">
                    Roles *
                  </label>
                  <select
                    class="select2 form-select"
                    value={values?.occupation_role_id}
                    onChange={(e) => {
                      setFieldValue("occupation_role_id", e.target.value || "");
                    }}
                  >
                    <option value="">Select</option>
                    {roles.map((role) => {
                      return <option value={role?.id}>{role?.name}</option>;
                    })}
                  </select>
                  <ErrorMessage
                    name="occupation_role_id"
                    component="div"
                    className="invalid-form"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-4">
            <h5 class="card-header">Billing Address</h5>
            <div class="card-body">
              <div class="col-12 mb-2 ms-3">
                <div class="form-check form-check-inline">
                  <input
                    name="collapsible-payment"
                    class="form-check-input"
                    type="radio"
                    id="collapsible-payment-cc"
                    checked={addressType === "personal"}
                    onChange={() => {
                      setAddressType("personal");
                      handleBillingInfo("personal", setValues, values);
                    }}
                  />
                  <label class="form-check-label" for="collapsible-payment-cc">
                    Use My Personal Billing Information
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    name="collapsible-payment"
                    class="form-check-input"
                    type="radio"
                    id="collapsible-payment-cc"
                    checked={addressType === "company"}
                    onChange={() => {
                      setAddressType("company");
                      handleBillingInfo("company", setValues, values);
                    }}
                  />
                  <label class="form-check-label" for="collapsible-payment-cc">
                    Use My Company Billing Information
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-sm-6">
                  <label for="companyName" class="form-label">
                    Official Company Name *
                  </label>
                  <Field
                    type="text"
                    name="bi_company"
                    label="Official Company Name *"
                    class="form-control"
                  />
                  <ErrorMessage
                    name="bi_company"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label for="billingEmail" class="form-label">
                    Address *
                  </label>
                  <Field
                    class="form-control"
                    type="text"
                    name="bi_address"
                    label="Address *"
                  />
                  <ErrorMessage
                    name="bi_address"
                    component="div"
                    className="invalid-form"
                  />
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label">Country *</label>
                  <select
                    id="country"
                    class="select2 form-select"
                    value={values?.bi_country_id}
                    onChange={(e) => {
                      setFieldValue("bi_country_id", e.target.value || "");
                    }}
                  >
                    <option value="">Select</option>
                    {countries.map((country) => {
                      return (
                        <option value={country?.id}>{country?.name}</option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="bi_country_id"
                    component="div"
                    className="invalid-form"
                  />
                </div>

                <div class="mb-3 col-sm-6">
                  <label for="taxId" class="form-label">
                    State/Province *
                  </label>
                  <Field
                    type="text"
                    name="bi_state"
                    label="State/Province *"
                    class="form-control"
                  />
                  <ErrorMessage
                    name="bi_state"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label for="vatNumber" class="form-label">
                    Postal Code/ Zip Code *
                  </label>
                  <Field
                    class="form-control"
                    type="text"
                    name="bi_postal_code"
                    label="Postal Code/ Zip Code *"
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue(
                        "bi_postal_code",
                        e.target.value.replace(/[^0-9]/g, "")
                      );
                    }}
                  />
                  <ErrorMessage
                    name="bi_postal_code"
                    component="div"
                    className="invalid-form"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label for="mobileNumber" class="form-label">
                    City *
                  </label>
                  <div class="input-group input-group-merge">
                    <Field
                      class="form-control mobile-number"
                      type="text"
                      name="bi_city"
                      label="City *"
                      placeholder="202 555 0111"
                    />
                  </div>
                  <ErrorMessage
                    name="bi_city"
                    component="div"
                    className="invalid-form"
                  />
                </div>
              </div>
              <div class="mt-2">
                <button type="submit" class="btn btn-primary me-2">
                  Continue
                </button>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-primary d-none"
            data-bs-toggle="modal"
            data-bs-target="#onboardingSlideModal"
            ref={modelButtonRef}
          >
            Multistep Slider modal
          </button>
          <div
            class="modal-onboarding modal fade animate__animated"
            id="onboardingSlideModal"
            tabindex="-1"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content text-center">
                <div class="modal-header border-0">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  id="modalCarouselControls"
                  class="carousel slide pb-4 mb-2"
                  data-bs-interval="false"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="onboarding-media">
                        <div class="mx-2">
                          <img
                            src="../../assets/img/illustrations/boy-with-rocket-dark.png"
                            alt="girl-with-laptop-light"
                            width="335"
                            class="img-fluid"
                            data-app-light-img="illustrations/boy-with-rocket-dark.png"
                            data-app-dark-img="illustrations/girl-with-laptop-dark.png"
                          />
                        </div>
                      </div>
                      <div class="onboarding-content">
                        <h4 class="onboarding-title text-body text-success">
                          Membership Created Successfully
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BillingDetails;
