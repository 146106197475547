import { useDispatch } from "react-redux";
import { setToaster } from "../redux/slices/profile_slice";

export const useToaster = () => {
  const dispatch = useDispatch();
  const setSuccessToaster = (message) => {
    dispatch(setToaster({ open: true, message, type: "success" }));
  };

  const setErrorToaster = (message) => {
    dispatch(setToaster({ open: true, message, type: "error" }));
  };

  const closeToaster = () => {
    dispatch(setToaster({ open: false, message: "", type: "" }));
  };

  return {
    setSuccessToaster,
    setErrorToaster,
    closeToaster,
  };
};
