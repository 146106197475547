import React from "react";

const MembershipCard = () => {
  return (
    <div className="card mb-4 viewMemberCard col-12  my-4">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <span className="badge bg-label-primary">Standard</span>
            <div className="d-flex justify-content-center ">
              <sup className="h6 pricing-currency mt-3 mb-0 me-1  fw-normal text-color">
                $
              </sup>
              <h1 className="mb-0 text-color">99</h1>
              <sub className="h6 pricing-duration mt-auto mb-2  fw-normal text-color">
                /month
              </sub>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {" "}
            <img
              src="assets/img/icons/misc/authentication-QR.png"
              alt="auth-login-cover"
              className="img-fluid  "
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>

        <ul className="ps-3 g-2 my-3">
          <li className="mb-2">10 Users</li>
          <li className="mb-2">Up to 10 GB storage</li>
          <li>Basic Support</li>
        </ul>
        <div className="d-flex justify-content-between align-items-center mb-1 fw-medium text-color">
          <span>Days</span>
          <span>65% Completed</span>
        </div>
        <div className="progress mb-1" style={{ height: "8px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: "65%" }}
          ></div>
        </div>
        <span>4 days remaining</span>
        <div className="d-grid w-100 mt-4">
          <button className="btn btn-transaparent text-color">
            Claim Membership
          </button>
        </div>
      </div>
    </div>
  );
};

export default MembershipCard;
