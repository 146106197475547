import React from 'react'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        value={value}
        onClick={onClick}
        // ref={ref}
        placeholder="Select date"
        readOnly // Prevent typing in the input
      />
      {/* <span className="input-group-text">
        <FontAwesomeIcon icon={faCalendar} />
      </span> */}
    </div>
  );
  
  const CustomDatepicker = ({ field, form, min, max }) => {
    const { setFieldValue } = form;
    const { name, value } = field;
    const minDate = moment("01-01-1900", "DD-MM-YYYY").toDate();
    return (
      <DatePicker
        selected={value ? moment(value, "DD-MM-YYYY").toDate() : null}
        onChange={(date) =>
          setFieldValue(name, moment(date).format("DD-MM-YYYY"))
        }
        dateFormat="dd-MM-yyyy"
        className="form-control"
        placeholderText="Select date"
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        minDate={min || minDate}
        maxDate={max || null}
        customInput={<CustomInput />}
      />
    );
  };

export default CustomDatepicker;
