import { Container } from "components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { MEMBERSHIPALL } from "services/api.config";

export const ShowPlansAll = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState([]);

  const showPlans = async () => {
    try {
      const response = await api.get(`/${MEMBERSHIPALL}`);
      const plansList = response?.data?.data?.membershipPlans;
      setShow(plansList);
      console.log("showPlans:", plansList);
    } catch (error) {
      console.log(error);
    }
  };
  const getImage = (type) => {
    switch (type) {
      case "Individual":
        return "../../assets/img/illustrations/page-pricing-basic.png";
      case "Family":
        return "../../assets/img/illustrations/page-pricing-standard.png";
      case "Corporate":
        return "../../assets/img/illustrations/page-pricing-enterprise.png";
      default:
        return "../../assets/img/illustrations/page-pricing-basic.png";
    }
  };

  useEffect(() => {
    showPlans();
  }, []);
  return (
    <Container>
      <div className="content-wrapper">
        <div
          class="container-xxl flex-grow-1 container-p-y"
          style={{ height: "43px" }}
        >
          <h4 className="card-header">All plans</h4>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary  me-5 mb-4"
              onClick={() => {
                navigate("/addPlan");
              }}
            >
              Add plan
            </button>
          </div>

          <div className="card-datatable table-responsive">
            <table className="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
              <thead>
                <tr>
                  <th>Title</th>
                  <th className="text-truncate">Membership Type</th>

                  <th>Short Text </th>
                  <th className="text-truncate">Duration</th>
                  <th className="text-truncate">no of user</th>
                  <th className="cell-fit">Actions</th>
                </tr>
              </thead>
              <tbody>
                {show?.map((entry, index) => (
                  <tr key={index}>
                    <td dangerouslySetInnerHTML={{ __html: entry?.title }} />
                    <td>{entry?.membership_type}</td>

                    <td
                      dangerouslySetInnerHTML={{ __html: entry?.short_text }}
                    />
                    <td>
                      UPTO {entry?.duration} {entry?.duration_type}
                    </td>
                    <td>{entry?.no_of_users} </td>
                    <td className="d-flex ">
                      <button
                        variant="outlined"
                        className="btn btn-primary me-2"
                        onClick={() => navigate(`/selectedPlan/${entry?.id}`)}
                      >
                        View
                      </button>
                      <button
                        variant="outlined"
                        className="btn btn-primary"
                        onClick={() => navigate(`/plansEdit/${entry?.id}`)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};
