import axios from "axios";
import { BASE_URL } from "./api.config";

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});

api.interceptors.response.use(
  (response) => {
    // Return the response if successful
    return response;
  },
  (error) => {
      console.log("error",error)
    // Check if the error status is 401 Unauthorized or 400 Bad Request
    if (error.response && (error.response.status === 401 || error.response.status === 400)) {
      localStorage.removeItem("user")
      // Redirect to the homepage (assuming you're using React Router DOM for navigation)
      window.location.href = '/';
    }

    // Return the error to continue handling it elsewhere
    return Promise.reject(error);
  }
);


// 2255|vTtr5BYlXVzxeK2IS3giPkmtnSy7K7xlHgCx1FNB
// Add request interceptor to attach Authorization header
api.interceptors.request.use(
  (config) => {
    const tokenString = localStorage.getItem("user");
    const userToken = tokenString ?  JSON.parse(tokenString) :"";
    if (userToken?.token) {
      config.headers.Authorization = "Bearer " + userToken?.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
