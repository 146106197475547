import { Container } from "components";
import { useToaster } from "hooks";
import React, { useEffect, useState } from "react";
import api from "services/api";

export const Permissions = () => {
  const [role, setRole ] = useState([]);
  const [roleId , setRoleId ] = useState("");
  const [initialCheck , setInitialCheck] = useState([]);
  const [selectedCheckBoxes , setSelectedCheckBoxes ] = useState([])
  let order = ["Create", "Read", "Update", "Delete"];
  const {setSuccessToaster , setErrorToaster} = useToaster();


  // Fetch roles from the API (used in dropdown)
  const GetRole = async () => {
    try {
      const response = await api.get("/roles");
      const getRoleList = response?.data?.data?.anchors;
      setRole(getRoleList);
      console.log("GetRole:",getRoleList)
    } catch (error) {
      console.log("GetRole Error:",error)
    }
  }
  // Fetch modules and actions to initialize permissionsPayload (CHECK BOX)
  const getPermissionCheckbox =async () => {
    try {
      const response = await api.get("/modules-actions");
      const getPermissionCheckboxList = response?.data?.data;
      setInitialCheck(getPermissionCheckboxList)
      console.log("getPermissionCheckboxList :",getPermissionCheckboxList);
    } catch (error) {
      console.log("getPermissionCheckbox error:",error)
    }
  }
  useEffect( () => {
    GetRole()
    getPermissionCheckbox();
  },[])
  // Arranges the actions 
  const handleCheckBoxes = (array) => {
    let formatOrderArray = [];
    array && 
    order.forEach((element) => {
      let obj = array.find( (res) => res?.action === element)
      formatOrderArray.push(obj)
    });
    return formatOrderArray;
  }
  // Dynamically updates the permissions state (selectedCheckBoxes)
  const handleRolePermissions = (module_id,action_id,checked) => {
    const payload = [...selectedCheckBoxes];
    const updatedPayLoad = payload.map((mod) => {
      if (mod.module_id === module_id) {
        return {
          ...mod,
          actions: checked
            ? [...mod.actions, action_id]
            : mod.actions.filter((id) => id !== action_id),
        };
      }
      return mod;
    });
    setSelectedCheckBoxes(updatedPayLoad);
  }
  const submitPayload = async () => {
    try {
      const payload = selectedCheckBoxes.filter(
        (res) => res.actions.length !== 0
      );
      const response = await api.post(`/roles/${roleId}/permissions`, {
        permissions: payload,
      });
      const submitPayloadList = response?.data?.message;
      setSuccessToaster(submitPayloadList);
      console.log("response", submitPayloadList);
      // resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.log("submitPayload error:", error);
      setErrorToaster(error?.response?.data?.message);
    }
  };

   // Fetch the permissions for the selected role based on roleId(SELECT OPTION ONCHANGE  RELATED DATA)
   const getRolePermissions = async (roleId) => {
    try {
      const response = await api.get(`/roles/${roleId}/permissions`);
      const rolePermissions = response?.data?.data;

      const updatedPayload = initialCheck.map((moduleM) => ({
        module_id: moduleM.module_id,
        actions: rolePermissions
          .find((roleMod) => roleMod.module_id === moduleM.module_id)
          ?.actions.map((action) => action.action_id) || [],
      }));

      setSelectedCheckBoxes(updatedPayload);
      console.log("Role permissions fetched and mapped:", updatedPayload);
    } catch (error) {
      console.log("getRolePermissions error:", error);
    }
  };

  useEffect(() => {
    if (roleId) {
      getRolePermissions(roleId); // Fetch permissions for the selected role
    }else {
      setSelectedCheckBoxes([])
    }
  }, [roleId]);

  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="card-header mb-3">Permissions</h4>
          <div className="card-body">
            <div className="col-md-4">
              <select 
                className="form-select"
                onChange={(e) => {
                  setRoleId(e.target.value);
                  console.log("setRoleId:",e.target.value)
                }}
              >
                <option value="">Select</option>
                {role?.map( (fetRole) => (
                  <option key={fetRole?.role_id} value={fetRole?.role_id}>
                    {fetRole?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="table-responsive">
                <table className="invoice-list-table table  dataTable no-footer dtr-column mb-5">
                  <thead>
                    <tr>
                      <th className="min-w-150px"></th>
                      {order?.map( (res) => (
                        <th key={res} className="min-w-140px">{res}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {initialCheck?.map( (moduleM) => (
                      <tr key={moduleM?.module} >
                        <td>{moduleM?.module}</td>
                        {handleCheckBoxes(moduleM?.actions).map( (act) => {
                          if(!act) return <td key={Math.random()}></td>
                          return (
                            <td key={act?.action_id} >
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedCheckBoxes
                                    .find(
                                      (mod) =>
                                        mod.module_id === moduleM.module_id
                                    )
                                    ?.actions.includes(act.action_id)}
                                  onChange={(e) => {
                                    handleRolePermissions(
                                      moduleM?.module_id,
                                      act?.action_id,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
            <button className="btn btn-primary" onClick={submitPayload}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};
