import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer class="content-footer footer bg-footer-theme">
      <div class="container-xxl">
        <div class="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
          <div>
            ©<script>document.write(new Date().getFullYear());</script>, made
            with ❤️ by
            <Link to="#" class="footer-link text-primary fw-medium" >
              Pixinvent
            </Link>
          </div>
          <div class="d-none d-lg-inline-block">
            <Link to="#" class="footer-link me-4">
              License
            </Link>
            <Link to="#" class="footer-link me-4">
              More Themes
            </Link>

            <Link to="#"  class="footer-link me-4">
              Documentation
            </Link>

            <Link to="#" class="footer-link d-none d-sm-inline-block"  >
              Support
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
