import { useState } from "react";

export const useAuth = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem("user");
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const getUserDetails = () => {
    const tokenString = localStorage.getItem("user");
    const userToken = JSON.parse(tokenString);
    return userToken?.user;
  };

  const updateUserDetails = (key, value) => {
    const user = localStorage.getItem("user");
    const updateUser = JSON.parse(user);
    updateUser.user[key] = value;
    localStorage.setItem("user", JSON.stringify(updateUser));
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUserDetails());

  const removeToken = () => {
    localStorage.removeItem("user");
    setToken(null);
    setUser(null);
  };

  return {
    user,
    token,
    removeToken,
    updateUserDetails,
  };
};
