import { Container } from "components";
import CustomDatepicker from "components/CustomDatePicker";
import { ErrorMessage, Formik, Field, Form } from "formik";
import { useToaster } from "hooks";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "services/api";
import * as Yup from "yup";

const initialValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  email: "",
  phone_no: "",
  dob: "",
  address: "",
  city: "",
  postal_code: "",
  sex: "",
  state: "",
  country_id: null, // Initialize country as null or any default value
  occupation_role_id: null,
  company: "",
  country_name: "",
  account_type: "",
  company_sector: "",
  company_address: "",
};

export const UserDetailsEdit = () => {
  const [countries, setCountries] = useState([]);
  const parmas = useParams();
  const [profileData, setProfileData] = useState({ ...initialValues });
  const [profileImage, setProfileImage] = useState("");
  const { setErrorToaster, setSuccessToaster } = useToaster();
  const sexOptions = ["Male", "Female", "Other"];
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef?.current?.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();

    formData.append("img", file);
    formData.append("user_id", profileData?.user_id);
    try {
      const response = await api.post("/profile-picture", formData);
      if (response) {
        fetchUserDetails();
        setSuccessToaster("user details updated successfully");
      } else {
        setErrorToaster("Failed to update user details");
      }
    } catch (error) {
      console.log("uploadImage:", error);
    }
  };
  const validationSchema = Yup.object().shape({
    // user_id: Yup.number().typeError("User ID must be a number"),
    country_id: Yup.number().typeError("Country ID must be a number"),

    dob: Yup.string().required("DOB IS Required"),
    city: Yup.string()
      .required("City is required")
      .min(3, "City must be at least 3 characters"),
    state: Yup.string()
      .required("State is required")
      .min(3, "State must be at least 3 characters"),
    postal_code: Yup.string()
      .matches(/^\d{3,6}$/, "Postal code must be between 3 and 6 digits")
      .required("Postal code is required"),
    address: Yup.string()
      .required("Address is required")
      .min(10, "Address must be at least 10 characters"),
    first_name: Yup.string()
      .required("First name is required")
      .min(3, "First name must be at least 3 characters")
      .matches(/^[A-Za-z]+$/, "Only Alphabets"),
    middle_name: Yup.string()
      // .required("Middle name is required")
      // .min(3, "middle name must be at least 3 characters")
      .matches(/^[A-Za-z]+$/, "Only Alphabets"),
    last_name: Yup.string()
      // .required("Last name is required")
      // .min(3, "Last name must be at least 3 characters")
      .matches(/^[A-Za-z]+$/, "Only Alphabets"),
    phone_no: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Only Numaric")
      .length(10, "Phone number must be exactly 10 digits"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const fetchUserDetails = async () => {
    try {
      const response = await api.get(`/users/${parmas?.id}`);
      const data = response?.data?.data?.user;
      if (data) {
        handleProfileData(data);
        console.log("data:", data);
      }
    } catch (error) {
      console.log("fetchUserDetails editProfile Error :", error);
    }
  };
  const fetchContries = async () => {
    try {
      const response = await api.get("/countries");
      const countriesList = response?.data?.data?.countries || [];
      setCountries(countriesList);
      console.log("fetchContries:", countriesList);
    } catch (error) {
      console.log("fetchContries Error:", error);
    }
  };
  useEffect(() => {
    fetchContries();
  }, []);
  useEffect(() => {
    fetchUserDetails();
  }, []);
  const handleProfileData = (data) => {
    const { first_name, last_name, email, profile, middle_name, user_id } =
      data;
    const updateData = {
      first_name,
      last_name,
      middle_name,
      email,
      phone_no: profile?.phone_no,
      dob: profile?.dob
        ? moment(profile?.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        : null,
      sex: profile?.sex,
      account_type: profile?.account_type || "",
      country_name: profile?.country_name,
      address: profile?.address,
      city: profile?.city,
      postal_code: profile?.postal_code,
      state: profile?.state,
      country_id: profile?.country_id, // Initialize country as null or any default value
      position: profile?.position,
      occupation_role_id: profile?.occupation_role_id || null,
      company: profile?.company,
      company_sector: profile?.company_sector,
      company_address: profile?.company_address,
      user_id: user_id,
    };
    setProfileImage(profile?.profile_pic_url);
    setProfileData(updateData);
  };

  const handleSubmit = async (values) => {
    try {
      let payload = {
        ...values,
        dob: moment(values.dob).format("DD-MM-YYYY"),
      };
      const response = await api.post("/profile", payload);
      fetchUserDetails();
      setSuccessToaster("Profile Updated Successfully");
      navigate("/memberships");
    } catch (err) {
      let message = err?.response?.data?.message || "Failed To Update Data";
      setErrorToaster(message);
      console.log("err:", message);
    }
  };

  return (
    <Container>
      <div className="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/memberships">Users</Link>
              </li>
              <li class="breadcrumb-item active ">Edit user</li>
            </ol>
          </nav>
          <div class="row">
            <div class="col-md-12">
              {/* <AccountMenu /> */}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the input visually
                ref={fileInputRef} // Ref to access the input element
              />
              <Formik
                initialValues={profileData}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  console.log("values:", values);
                  handleSubmit(values);
                }}
                enableReinitialize
              >
                {({ values, setFieldValue, dirty, errors }) => (
                  <Form>
                    {console.log("errors:", errors)}
                    <div class="card mb-4">
                      <h5 class="card-header">Edit user details</h5>

                      <div class="card-body">
                        <div class="d-flex align-items-start align-items-sm-center gap-4">
                          <img
                            src={profileImage}
                            alt="user-avatar"
                            class="d-block w-px-100 h-px-100 rounded"
                            id="uploadedAvatar"
                          />
                          <div class="button-wrapper">
                            <label
                              for="upload"
                              class="btn btn-primary me-2 mb-3"
                              tabindex="0"
                              onClick={handleClick}
                            >
                              <span class="d-none d-sm-block">
                                Upload new photo
                              </span>
                            </label>

                            <div class="text-muted">
                              Allowed JPG, GIF or PNG. Max size of 800K
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-0" />
                      <h5 class="card-header pb-3">
                        Please fill details as per Aadhar card{" "}
                      </h5>

                      <div class="card-body">
                        <div className="row mb-3">
                          <div className="col-md-4 col-12">
                            <label for="username" className="form-label">
                              First Name <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="first_name"
                              placeholder="First Name"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                  setFieldValue("first_name", e.target.value);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="first_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-4 col-12">
                            <label for="middle_name" className="form-label">
                              Middle Name
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="middle_name"
                              placeholder="Middle Name"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                  setFieldValue("middle_name", e.target.value);
                                }
                              }}
                            />

                            <ErrorMessage
                              name="middle_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-4 col-12">
                            <label for="last_name" className="form-label">
                              Last Name
                            </label>

                            <Field
                              type="text"
                              className={`form-control `}
                              name="last_name"
                              placeholder="Last Name"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                  setFieldValue("last_name", e.target.value);
                                }
                              }}
                            />

                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label for="email" className="form-label">
                              Email <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="email"
                              className={`form-control`}
                              name="email"
                              placeholder="Enter your Email "
                              autofocus
                              disabled
                            />

                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-form"
                            />
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmlFor="phone_no" className="form-label">
                              Phone No <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="tel"
                              className={`form-control`}
                              name="phone_no"
                              placeholder="Enter your  Phone No"
                              autofocus
                              maxLength="10"
                              onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                  setFieldValue("phone_no", e.target.value);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="phone_no"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="dob" className="form-label">
                              DOB <span className="invalid-form">*</span>
                            </label>
                            {/* <Field
                              type="date"
                              className={`form-control`}
                              name="dob"
                              placeholder=""
                              format="dd-mm-yyyy"
                              autofocus
                            /> */}
                            <Field
                              name="dob"
                              className={`form-control`}
                              component={CustomDatepicker}
                              value={values.dob}
                            />
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label className="form-label" htmlFor="sex">
                              Sex <span className="invalid-form">*</span>
                            </label>
                            <Field
                              as="select"
                              className="select2 form-select"
                              name="sex"
                            >
                              <option value="">Select</option>
                              {sexOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="sex"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="country_id" className="form-label">
                              Country <span className="invalid-form">*</span>
                            </label>
                            <Field
                              as="select"
                              className="select2 form-select"
                              name="country_id"
                            >
                              <option value="">Select</option>
                              {countries.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="country_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label
                              className="form-label"
                              htmlFor="account_type"
                            >
                              Account Type{" "}
                              <span className="invalid-form">*</span>
                            </label>
                            <Field
                              as="select"
                              className="select2 form-select"
                              name="account_type"
                              disabled
                            >
                              <option value="">Select</option>
                              <option value="Personal">Personal</option>
                              <option value="Corporate">Corporate</option>
                            </Field>
                            <ErrorMessage
                              name="function_id"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="address" className="form-label">
                              Address <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="address"
                              placeholder="Address"
                              autofocus
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="city" className="form-label">
                              City <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="city"
                              placeholder="City"
                              autofocus
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label htmlFor="postal_code" className="form-label">
                              Postal Code{" "}
                              <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="postal_code"
                              placeholder="Postal_code"
                              autofocus
                              onChange={(e) => {
                                if (/^[A-Za-z0-9 ]*$/.test(e.target.value)) {
                                  setFieldValue("postal_code", e.target.value);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="postal_code"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="state" className="form-label">
                              State <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="state"
                              placeholder="State"
                              autofocus
                            />
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        {values?.account_type === "Corporate" && (
                          <>
                            <div className="row mb-3">
                              <div className="col-md-6 col-12">
                                <label for="company" className="form-label">
                                  Company Name
                                  <span className="invalid-form">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control`}
                                  name="company"
                                  placeholder="Company Name"
                                  autofocus
                                />
                                <ErrorMessage
                                  name="company"
                                  component="div"
                                  className="invalid-form"
                                />
                              </div>
                              <div className="col-md-6 col-12">
                                <label
                                  for="company_sector"
                                  className="form-label"
                                >
                                  Company Sector{" "}
                                  <span className="invalid-form">*</span>
                                </label>

                                <Field
                                  type="text"
                                  className={`form-control `}
                                  name="company_sector"
                                  placeholder="Company Sector"
                                  autofocus
                                />

                                <ErrorMessage
                                  name="company_sector"
                                  component="div"
                                  className="invalid-form"
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className=" col-12">
                                <label
                                  for="company_address"
                                  className="form-label"
                                >
                                  Company Address{" "}
                                  <span className="invalid-form">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control`}
                                  name="company_address"
                                  placeholder="Company Address"
                                  autofocus
                                />

                                <ErrorMessage
                                  name="company_address"
                                  component="div"
                                  className="invalid-form"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                        >
                          Modify
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
