import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Container } from "components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth, useToaster } from "hooks";
import { useSelector, useDispatch } from "react-redux";
import api from "services/api";
import moment from "moment";
import { RELATIONS_ENDPOINT } from "services/api.config";
import ApplicationForm from "./applicationForm";
import { addCountries } from "../../../redux/slices/profile_slice";

const ApplicationDetails = ({ setActiveStep }) => {
  const [activeMembership, setActiveMembership] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [documentType, setDocumentFile] = useState("");
  const [imageIndex, setImageIndex] = useState(null);
  const [relations, setRelations] = useState([]);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state?.profile);
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { setSuccessToaster, setErrorToaster } = useToaster();
  const fileInputRef = useRef(null);
  const kycInputRef = useRef(null);
  const [addMember, setAddMember] = useState(false);
  const membershipData = {
    membership_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    img: "",
    imgpath: "",
    dob: "",
    sex: "",
    relation: "",
    nationality: "",
  };
  const [initialValues, setInitialValues] = useState([]);

  const fetchUserDetails = async () => {
    try {
      const response = await api.get(`/users/${user?.user_id}`);
      const data = response?.data?.data?.user;
      setInitialValues([
        {
          membership_id: "",
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          phone_no: data.profile.phone_no,
          email: data.email,
          img: "",
          imgpath: data.profile.profile_pic_url,
          dob: data.profile.dob,
          sex: data.profile.sex,
          relation: data.profile.relation,
          nationality: data.profile.nationality,
        },
      ]);
      console.log(data);
    } catch (error) {
      console.log("error response:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is Required"),
    // last_name: Yup.string().required("Required"),
    // phone_no: Yup.string().required("Required"),
    // email: Yup.string().email("Invalid email").required("Required"),
    // img: Yup.string(),
    // dob: Yup.date().required("Required"),
    // sex: Yup.string().required("Required"),
    // relation: Yup.string().required("Required"),
    // nationality: Yup.string().required("Required"),
  });

  const fetchRelaction = async () => {
    try {
      const response = await api.get(RELATIONS_ENDPOINT);
      setRelations(response.data.data.relations);
    } catch (error) {}
  };

  useEffect(() => {
    fetchMembership();
    fetchRelaction();
    if (countries?.length === 0) {
      api.get("/countries").then((res) => {
        let countriesList = res?.data?.data?.countries || [];
        if (countriesList?.length > 0) {
          dispatch(addCountries(countriesList));
        }
      });
    }
  }, [params?.id]);

  const fetchMembership = () => {
    api.get(`/membership-plans/${params?.id}`).then((res) => {
      if (res?.data?.data?.membershipPlans) {
        setActiveMembership(res?.data?.data?.membershipPlans);
      }
    });
  };

  const handleSubmit = (values) => {
    let payload = [];
    values.members.forEach((mem) => {
      let obj = { ...mem };
      delete obj.imgpath;
      obj.dob = moment(obj.dob).format("DD-MM-YYYY");
      payload.push(obj);
    });
    const formData = new FormData();
    payload.forEach((res, i) => {
      Object.entries(res).forEach((mem) => {
        formData.append(`${mem[0]}[${i}]`, mem[1]);
      });
    });
    formData.append("membership_id", activeMembership.id);
    formData.append("user_id", user?.user_id);
    api
      .post("/membership-application", formData)
      .then((res) => {
        // setSuccessToaster("Application Details Submitted Successfully");

        const paymentData = res.data.paymentData;
        console.log(res.data.paymentData);

        const form = document.createElement("form");
        form.method = "POST";
        form.action = paymentData.action;

        Object.keys(paymentData).forEach((key) => {
          if (key !== "action") {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = paymentData[key];
            form.appendChild(input);
          }
        });

        document.body.appendChild(form);
        form.submit();
      })
      .catch((err) => {
        setErrorToaster(err?.response?.data?.message || "Something went wrong");
      });
  };

  const handleClick = (i) => {
    setImageIndex(i);
    fileInputRef.current.click();
  };

  const handleFileChange = (event, setValues, values) => {
    const file = event.target.files[0];
    if (file) {
      let obj = { ...values.members[imageIndex] };
      obj.img = file;
      obj.imgpath = URL.createObjectURL(file);
      let updateValues = [...values.members];
      updateValues[imageIndex] = obj;
      console.log("updateValues", updateValues);
      setValues({
        members: updateValues,
      });
      setImageIndex(null);
    }
  };

  const addMemBeship = (values, setValues) => {
    const updateValues = {
      members: [...values.members, { ...membershipData }],
    };
    setValues(updateValues);
  };

  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y"></div>
        <Formik
          initialValues={{ members: initialValues }}
          validationSchema={Yup.object({
            members: Yup.array().of(validationSchema),
          })}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, setFieldValue, errors, setValues }) => (
            <>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, setValues, values)}
                style={{ display: "none" }} // Hide the input visually
                ref={fileInputRef} // Ref to access the input element
              />

              <Form>
                {values?.members?.map((mem, i) => {
                  return (
                    <ApplicationForm
                      setFieldValue={setFieldValue}
                      relations={relations}
                      countries={countries}
                      values={values}
                      addMemBeship={addMemBeship}
                      setValues={setValues}
                      mem={mem}
                      isLast={
                        values?.members?.length - 1 === i &&
                        activeMembership?.no_of_users !==
                          values?.members?.length
                      }
                      i={i}
                      handleClick={handleClick}
                    />
                  );
                })}

                <div class="card mb-4  mx-4">
                  <h5 class="card-header">Consent</h5>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mb-1">
                        <div class="mb-3">
                          <p>
                            <Field
                              class="form-check-input me-2"
                              type="checkbox"
                              name="agree"
                            />{" "}
                            I agree to Demo Chapter 1{" "}
                            <a href="">Terms of for Users</a> and{" "}
                            <a href="">Privacy Policy</a> <br />I also to recive
                            comminications regarding events, news and
                            promotions, I can unsubscribe at any time.
                          </p>
                          <ErrorMessage
                            name="agree"
                            component="div"
                            className="invalid-form"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <button type="submit" class="btn btn-primary me-2">
                        Buy
                      </button>
                      <button type="reset" class="btn btn-label-secondary">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
        <div class="content-backdrop fade"></div>
      </div>
    </Container>
  );
};

export default ApplicationDetails;
