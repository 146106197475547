import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHouse,
  faFileCirclePlus,
  faListCheck,
  faUserGear,
  faUserShield,
  faPenNib,
  faHandHoldingDollar,
  faHandshake,
  faBell,
  faPeopleArrows,
  faHeart,
  faFilter,
  faTag,
  faUserSecret,
  faShopLock,
  faParachuteBox,
} from "@fortawesome/free-solid-svg-icons";

<i class="fa-solid fa-people-arrows"></i>;

export const Sidenav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dashboardMenu, setDashBoardMenu] = useState("");
  const [accountMenu, setAccountMenu] = useState("");
  const { pathname } = location;

  const handleDashboardMenu = () => {
    if (dashboardMenu === "open") {
      setDashBoardMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setDashBoardMenu("");
      }, 200);
    } else {
      setDashBoardMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setDashBoardMenu("open");
      }, 200);
    }
  };

  const handleAccountMenu = () => {
    if (accountMenu === "open") {
      setAccountMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setAccountMenu("");
      }, 200);
    } else {
      setAccountMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setAccountMenu("open");
      }, 200);
    }
  };

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo">
        <a className="app-brand-link">
          <span className="app-brand-logo demo">
            <svg
              width="32"
              height="22"
              viewBox="0 0 32 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z"
                fill="#7367F0"
              />
              <path
                opacity="0.06"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z"
                fill="#161616"
              />
              <path
                opacity="0.06"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z"
                fill="#161616"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z"
                fill="#7367F0"
              />
            </svg>
          </span>
          <span className="app-brand-text demo menu-text fw-bold">
            Zenosure
          </span>
        </a>

        {/* <a
          href="javascript:void(0);"
          className="layout-menu-toggle menu-link text-large ms-auto"
        >
          <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
          <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
        </a> */}
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1">
        <li className={`menu-item ${pathname === "/dashboard" && "active"}`}>
          <Link to="/dashboard" className="menu-link ">
            <div data-i18n="Dashboard">
              {" "}
              <FontAwesomeIcon icon={faHouse} size="xs" className="me-2" />
              Dashboard
            </div>
          </Link>
        </li>
        <li className={`menu-item ${(pathname === "/showplansall" || pathname.startsWith("/selectedPlan") || pathname.startsWith("/plansEdit") || pathname.startsWith("/addPlan")) ? "active" : ""}`}>
          <Link to="/showplansall" className="menu-link ">
            <div data-i18n="Dashboard">
              {" "}
              <FontAwesomeIcon icon={faHeart} size="xs" className="me-2" />
              Plans
            </div>
          </Link>
        </li>
        <li className={`menu-item ${(pathname === "/memberships" || pathname.startsWith("/membershipDetails") || pathname.startsWith("/userDetailsEdit") || pathname.startsWith("/addUser"))? "active" : "" }`}>
          <Link to="/memberships" className="menu-link ">
            <div data-i18n="Dashboard">
              {" "}
              <FontAwesomeIcon
                icon={faPeopleArrows}
                size="xs"
                className="me-2"
              />
              Users
            </div>
          </Link>
        </li>

        <li className={`menu-item ${(pathname === "/corperators" || pathname.startsWith("/corperatorsDetails")) ? "active" : ""}`}>
          <Link to="/corperators" className="menu-link ">
            <div data-i18n="Dashboard">
              
              <FontAwesomeIcon
                icon={faFileCirclePlus}
                size="xs"
                className="me-2"
              />
              Corperators
            </div>
          </Link>
        </li>

        <li
          className={`menu-item active ${dashboardMenu} ${
           pathname === "/Partnerwithus"|| pathname === "/PrivacyPolicy" ||  pathname === "/termsConditions" ||   pathname === "/aboutUs" ||   pathname === "/permissions" ? "open" : ""
          }`}
          onClick={handleDashboardMenu}
        >
          <a href="javascript:void(0);" className="menu-link menu-toggle">
            <div data-i18n="Dashboard">
              {" "}
              <FontAwesomeIcon icon={faUserGear} size="xs" className="me-2" />
              Settings
            </div>
          </a>
          <ul className="menu-sub" style={{ listStyle: "none" }}>
            <li className={`menu-item ${pathname === "/Partnerwithus" && "active"}`}>
              <Link to="/Partnerwithus" className="menu-link">
                <FontAwesomeIcon icon={faUser} size="xs" className="me-2" />{" "}
                 Partner with us
              </Link>
            </li>
            <li className={`menu-item ${pathname === "/PrivacyPolicy" && "active"}`}>
              <Link to="/PrivacyPolicy" className="menu-link">
                <FontAwesomeIcon icon={faUserSecret} size="xs" className="me-2" />{" "}
                Privacy policy
              </Link>
            </li>
            <li className={`menu-item ${pathname === "/termsConditions" && "active"}`}>
              <Link to="/termsConditions" className="menu-link">
                <FontAwesomeIcon icon={faFilter} size="xs" className="me-2" />{" "}
                Terms and conditions
              </Link>
            </li>
            <li className={`menu-item ${pathname === "/aboutUs" && "active"}`}>
              <Link to="/aboutUs" className="menu-link">
                <FontAwesomeIcon icon={faTag} size="xs" className="me-2" />{" "}
                About us
              </Link>
            </li>
            <li className={`menu-item ${pathname === "/permissions" && "active"}`}>
              <Link to="/permissions" className="menu-link">
                <FontAwesomeIcon icon={faShopLock} size="xs" className="me-2" />{" "}
                Permissions
              </Link>
            </li>
          </ul>
        </li>

        <li
          className={`menu-item active ${accountMenu}`}
          onClick={handleAccountMenu}
        >
          <a href="javascript:void(0);" className="menu-link menu-toggle">
            <div data-i18n="Account Settings">
              <FontAwesomeIcon icon={faUserShield} size="xs" className="me-2" />{" "}
              Security
            </div>
          </a>
          <ul className="menu-sub">
            <li
              className={`menu-item ${
                pathname === "#" && "active"
              }`}
            >
              <Link to="#" className="menu-link">
                <div data-i18n="Account">
                  <FontAwesomeIcon icon={faPenNib} size="xs" className="me-2" />{" "}
                  Reset Password
                </div>
              </Link>
            </li>
          </ul>
        </li>
        <li className={`menu-item ${(pathname === "/partners" || pathname.startsWith("/partnersDetails") || pathname === "/addPartner" || pathname.startsWith("/editPartner")) ? "active" : ""}`}>
          <Link to="/partners" className="menu-link ">
            <div data-i18n="Dashboard">
              {" "}
              <FontAwesomeIcon icon={faParachuteBox} size="xs" className="me-2" />
              Partners
            </div>
          </Link>
        </li>
        <li className={`menu-item `}>
          <Link to="/dashboard" className="menu-link ">
            <div data-i18n="Dashboard">
              <FontAwesomeIcon
                icon={faHandHoldingDollar}
                size="xs"
                className="me-2"
              />{" "}
              Manage Payments
            </div>
          </Link>
        </li>

        <li className={`menu-item `}>
          <Link to="/dashboard" className="menu-link ">
            <div data-i18n="Dashboard">
              <FontAwesomeIcon icon={faHandshake} size="xs" className="me-2" />
              Help
            </div>
          </Link>
        </li>
        <li className={`menu-item `}>
          <Link to="/dashboard" className="menu-link ">
            <div data-i18n="Dashboard">
              <FontAwesomeIcon icon={faBell} size="xs" className="me-2" />{" "}
              Notifications
            </div>
          </Link>
        </li>
      </ul>
    </aside>
  );
};
