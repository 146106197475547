import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ totalPages, perPage, handleFunction }) => {
  let totalNumbers = Math.ceil(totalPages / perPage);
  const [currentRange, setCurrentRange] = useState({
    start: 1,
    end: totalNumbers > 5 ? 5 : totalNumbers,
  });
  const [activePage, setActivePage] = useState(1);

  // Center the range around the active page
  const updateRange = (newActivePage) => {
    let newStart = Math.max(1, newActivePage - Math.floor(5 / 2));
    let newEnd = Math.min(totalNumbers, newStart + 4);

    if (newEnd - newStart < 4) {
      newStart = Math.max(1, newEnd - 4);
    }

    setCurrentRange({ start: newStart, end: newEnd });
  };

  const handleNextPage = () => {
    if (activePage < currentRange.end) {
      const nextPage = activePage + 1;
      setActivePage(nextPage);
      handleFunction(nextPage);
      updateRange(nextPage);
    } else if (activePage < totalNumbers) {
      const nextPage = currentRange.end + 1;
      setActivePage(nextPage);
      handleFunction(nextPage);
      updateRange(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (activePage > currentRange.start) {
      const prevPage = activePage - 1;
      setActivePage(prevPage);
      handleFunction(prevPage);
      updateRange(prevPage);
    } else if (activePage > 1) {
      const prevPage = currentRange.start - 1;
      setActivePage(prevPage);
      handleFunction(prevPage);
      updateRange(prevPage);
    }
  };

  // const handleNextRange = () => {
  //   if (currentRange.end < totalNumbers) {
  //     const newStart = currentRange.start + 5;
  //     setCurrentRange({
  //       start: newStart,
  //       end: Math.min(totalNumbers, newStart + 4),
  //     });
  //     setActivePage(newStart);
  //     handleFunction(newStart);
  //   }
  // };

  // const handlePrevRange = () => {
  //   if (currentRange.start > 1) {
  //     const newEnd = currentRange.start - 1;
  //     setCurrentRange({
  //       start: Math.max(1, newEnd - 4),
  //       end: newEnd,
  //     });
  //     setActivePage(Math.max(1, newEnd - 4));
  //     handleFunction(Math.max(1, newEnd - 4));
  //   }
  // };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        {/* {totalNumbers > 5 && (
          <li className="page-item first">
            <button
              className="page-link"
              onClick={handlePrevRange}
              disabled={currentRange.start === 1}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="xs" className="me-2" />
            </button>
          </li>
        )} */}

        <li className="page-item prev">
          <button
            className="page-link"
            onClick={handlePrevPage}
            disabled={activePage <= 1}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="xs" className="me-2" />
          </button>
        </li>

        {Array.from(
          { length: currentRange.end - currentRange.start + 1 },
          (_, i) => (
            <li
              className={`page-item ${
                activePage === i + currentRange.start ? "active" : ""
              }`}
              key={i + currentRange.start}
            >
              <button
                className="page-link"
                onClick={() => {
                  const selectedPage = i + currentRange.start;
                  setActivePage(selectedPage);
                  handleFunction(selectedPage);
                  updateRange(selectedPage);
                }}
              >
                {i + currentRange.start}
              </button>
            </li>
          )
        )}

        <li className="page-item next">
          <button
            className="page-link"
            onClick={handleNextPage}
            disabled={activePage >= totalNumbers}
          >
            <FontAwesomeIcon icon={faChevronRight} size="xs" className="me-2" />
          </button>
        </li>

        {/* {totalNumbers > 5 && (
          <li className="page-item last">
            <button
              className="page-link"
              onClick={handleNextRange}
              disabled={currentRange.end >= totalNumbers}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                size="xs"
                className="me-2"
              />
            </button>
          </li>
        )} */}
      </ul>
    </nav>
  );
};

export default Pagination;
