import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import api from "services/api";
import { useAuth } from "hooks";

export const Header = () => {
  const navigate = useNavigate();
  const { user, removeToken } = useAuth();
  const isLoggedIn = localStorage.getItem("user");

  const [profile, setProfile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleProfileClick = (event) => {
    setProfile(event.currentTarget);
  };
  const handleProfileClose = () => {
    setProfile(null);
  };

  const handleLogout = () => {
    api
      .post("/logout", {})
      .then((res) => {
        removeToken();
        navigate("/");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, []);

  return (
    <nav
      class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
          <i class="ti ti-menu-2 ti-sm"></i>
        </a>
      </div>

      <div
        class="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div class="navbar-nav align-items-center">
          <div class="nav-item navbar-search-wrapper mb-0">
            <a
              class="nav-item nav-link search-toggler d-flex align-items-center px-0"
              href="javascript:void(0);"
            >
              <i class="ti ti-search ti-md me-2"></i>
              <span class="d-none d-md-inline-block text-muted">
                {/* Search (Ctrl+/) */}
              </span>
            </a>
          </div>
        </div>

        <ul class="navbar-nav flex-row align-items-center ms-auto">
         

          <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              class="nav-link dropdown-toggle hide-arrow"
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
            >
              <div class="avatar avatar-online">
                <img
                  src="../../assets/img/avatars/14.jpg"
                  alt
                  class="h-auto rounded-circle"
                />
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  class="dropdown-item"
                  href="pages-account-settings-account.html"
                >
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar avatar-online">
                        <img
                          src="../../assets/img/avatars/14.jpg"
                          alt
                          class="h-auto rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <span class="fw-medium d-block">John Doe</span>
                      <small class="text-muted">Admin</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <Link class="dropdown-item" to="#">
                  <i class="ti ti-user-check me-2 ti-sm"></i>
                  <span class="align-middle">My Profile</span>
                </Link>
              </li>
              <li>
                <Link class="dropdown-item" to="#"    >
                  <i class="ti ti-settings me-2 ti-sm"></i>
                  <span class="align-middle">Settings</span>
                </Link>
              </li>
              <li>
                <Link to="#" class="dropdown-item" >
                  <span class="d-flex align-items-center align-middle">
                    <i class="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                    <span class="flex-grow-1 align-middle">Billing</span>
                    {/* <span class="flex-shrink-0 badge badge-center rounded-pill bg-label-danger w-px-20 h-px-20">
                      2
                    </span> */}
                  </span>
                </Link>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <Link class="dropdown-item" href="pages-faq.html">
                  <i class="ti ti-help me-2 ti-sm"></i>
                  <span class="align-middle">FAQ</span>
                </Link>
              </li>
              <li>
                <Link class="dropdown-item" href="pages-pricing.html">
                  <i class="ti ti-currency-dollar me-2 ti-sm"></i>
                  <span class="align-middle">Pricing</span>
                </Link>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <Link onClick={handleLogout}  class="dropdown-item"  >
                  <i class="ti ti-logout me-2 ti-sm"></i>
                  <span class="align-middle">Log Out</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="navbar-search-wrapper search-input-wrapper d-none">
        <input
          type="text"
          class="form-control search-input container-xxl border-0"
          placeholder="Search..."
          aria-label="Search..."
        />
        <i class="ti ti-x ti-sm search-toggler cursor-pointer"></i>
      </div>
    </nav>
  );
};
