import {
  faEye,
  faEyeSlash,
  faL,
  faMarker,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "components";
import CustomDatepicker from "components/CustomDatePicker";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useToaster } from "hooks";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "services/api";
import * as Yup from "yup";
const MINIMUM_AGE = 16;
const MINIMUM_YEAR = 1920;
const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 16);

const initialValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  dob: "",
  sex: "",
  phone_no: "",
  nationality: "95",
  password: "",
  password_confirmation: "",
  agree: false,
  role_id: "",
  user_image: "",
};

export const Adduser = () => {
  const fileInputRef = useRef();
  const sexOptions = ["Male", "Female", "Other"];
  const [image, setImage] = useState("");
  const [countries, setCountries] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { setErrorToaster , setSuccessToaster} = useToaster();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Required")
      .matches(/^[A-Za-z]+$/, "Only Alphabets"),
    middle_name: Yup.string().matches(/^[A-Za-z]+$/, "Only Alphabets"),
    last_name: Yup.string()
      // .required("Required")
      .matches(/^[A-Za-z]+$/, "Only Alphabets"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phone_no: Yup.string()
      .required("Required")
      .matches(/[0-9]+$/, "Only numeric")
      .length(10, "Phone number must be exactly 10 digits"),
    dob: Yup.string()
    .required("DOB IS Required")
    .test('min-date', 'You must be at least 16 years old', (value) => {
      const selectedDate = moment(value, "DD-MM-YYYY");
      const minDate = moment().subtract(16, 'years').startOf('day').toDate();
      return selectedDate.isSameOrBefore(minDate);
    }),
    sex: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    password: Yup.string()
      .required("Password is Required")
      .min(8, "Password must be at least 8 characters")
      .max(30, "Password must be at most 30 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    role_id: Yup.string().required("Required"),
    user_image: Yup.string().required("Required"),
    // agree: Yup.boolean()
    //   .oneOf([true], "Must agree to terms and conditions")
    //   .required("Required"),
  });

  const handleClick = () => {
    console.log("callingg");
    document.getElementById("upload").click();
  };
  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    console.log("image:", file);
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    const fileSize = 800 * 1024;

    if (file) {
      if (!validTypes.includes(file.type)) {
        setErrorToaster("Only JPG,GIF,or PNG files are allowed");
        return;
      }
      if (file.size > fileSize) {
        setErrorToaster("File size should not exceed 800KB.");
        return;
      }
    }
    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("user_image", file);
  };
  const handleDobChange = (e, setFieldValue) => {
    const selectedDate = new Date(e.target.value);
    const ageDifMs = Date.now() - selectedDate.getTime();
    const ageDate = new Date(ageDifMs); // Time from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age < MINIMUM_AGE) {
      setErrorToaster("Age must be 16 or above!");

      setFieldValue("dob", "");
    } else {
      // Valid date, set it in Formik
      setFieldValue("dob", e.target.value);
    }
  };
  const countriesFetch = async () => {
    try {
      const response = await api.get("/countries");
      const countriesList = response.data?.data?.countries;
      setCountries(countriesList);
      console.log("Countries:", countriesList);
    } catch (error) {
      console.log("countries error:", error);
    }
  };
  const fetchRole = async () => {
    try {
      const response = await api.get("/roles");
      const fetchRoleList = response?.data?.data?.anchors;
      setRoleId(fetchRoleList);
      console.log("fetchRole :", fetchRoleList);
    } catch (error) {
      console.log("fetchRole :", error);
    }
  };
  useEffect(() => {
    countriesFetch();
    fetchRole();
  }, []);
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();

      // Add other fields
      Object.keys(values).forEach((key) => {
        if (key === "dob") {
          formData.append(key, moment(values[key]).format("DD-MM-YYYY"));
        } else {
          formData.append(key, values[key]);
        }
      });

      // Add the user_image file
      formData.append("user_image", values.user_image);
      
      const response = await api.post("/users", formData);
      console.log("rasp:",response)
      if(response?.status === 201) {
        const submitList = response;
        setSuccessToaster(response?.data?.message)
        navigate("/memberships")
        // console.log("handleSubmit :", submitList);
      }
      
      
    } catch (error) {
      console.log("handleSubmit error :", error);
      setErrorToaster(error?.response?.data?.message)
    }
  };
  return (
    <Container>
      <div className="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/memberships">Users</Link>
              </li>
              <li class="breadcrumb-item active">Add user</li>
            </ol>
          </nav>
          <div class="row">
            <div class="col-md-12">
              {/* <AccountMenu /> */}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  console.log("values:", values);
                  handleSubmit(values);
                }}
              >
                {({ values, setFieldValue, dirty, errors }) => (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      name="user_image"
                      id="upload"
                      onChange={(e) => handleImageChange(e, setFieldValue)}
                      required
                      style={{ display: "none" }} // Hide the input visually
                      ref={fileInputRef} // Ref to access the input element
                    />
                    <Form>
                      {console.log("errors:", errors)}
                      <div class="card mb-4">
                        <h5 class="card-header">Add user</h5>

                        <div class="card-body">
                          <div class="d-flex align-items-start align-items-sm-center gap-4">
                            <img
                              src={
                                selectedImage ||
                                "../../assets/img/avatars/14.jpg"
                              }
                              alt="user-avatar"
                              class="d-block w-px-100 h-px-100 rounded"
                              id="uploadedAvatar"
                            />
                            <div class="button-wrapper">
                              <button
                                type="button"
                                class="btn btn-primary me-2 mb-3"
                                onClick={handleClick}
                              >
                                <span class="d-none d-sm-block">
                                  Upload new photo
                                </span>
                              </button>

                              <div class="text-muted">
                                Allowed JPG, GIF or PNG. Max size of 800K
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr class="my-0" />
                        <h5 class="card-header pb-3">
                          Please fill details as per Aadhar card{" "}
                        </h5>

                        <div class="card-body">
                          <div className="row mb-3">
                            <div className="col-md-4 col-12">
                              <label for="username" className="form-label">
                                First Name{" "}
                                <span className="invalid-form">*</span>
                              </label>
                              <Field
                                type="text"
                                className={`form-control`}
                                name="first_name"
                                placeholder="First Name"
                                autofocus
                                onChange={(e) => {
                                  if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                    setFieldValue("first_name", e.target.value);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="first_name"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <label for="middle_name" className="form-label">
                                Middle Name
                              </label>
                              <Field
                                type="text"
                                className={`form-control`}
                                name="middle_name"
                                placeholder="Middle Name"
                                autofocus
                                onChange={(e) => {
                                  if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                    setFieldValue(
                                      "middle_name",
                                      e.target.value
                                    );
                                  }
                                }}
                              />

                              <ErrorMessage
                                name="middle_name"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <label for="last_name" className="form-label">
                                Last Name
                              </label>

                              <Field
                                type="text"
                                className={`form-control `}
                                name="last_name"
                                placeholder="Last Name"
                                autofocus
                                onChange={(e) => {
                                  if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                    setFieldValue("last_name", e.target.value);
                                  }
                                }}
                              />

                              <ErrorMessage
                                name="last_name"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6 col-12">
                              <label htmlFor="role_id" className="form-label">
                                Role <span className="invalid-form">*</span>
                              </label>
                              <Field
                                as="select"
                                className={`select2 form-select`}
                                name="role_id"
                              >
                                <option value="">Select</option>
                                {roleId?.map((res) => (
                                  <option
                                    key={res?.role_id}
                                    value={res?.role_id}
                                  >
                                    {res?.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="role_id"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <label for="email" className="form-label">
                                Email <span className="invalid-form">*</span>
                              </label>
                              <Field
                                type="email"
                                className={`form-control`}
                                name="email"
                                placeholder="Enter your Email "
                                autofocus
                              />

                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6 col-12">
                              <label htmlFor="dob" className="form-label">
                                DOB <span className="invalid-form">*</span>
                              </label>
                              {/* <Field
                                type="date"
                                className={`form-control`}
                                name="dob"
                                placeholder=""
                                autofocus
                                onChange={(e) => {
                                  handleDobChange(e, setFieldValue);
                                }}
                              /> */}
                              <Field
                                name="dob"
                                component={CustomDatepicker}
                                value={values.dob}
                                max={moment().subtract(16, "years").startOf("day").toDate()}
                              />
                              <ErrorMessage
                                name="dob"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <label className="form-label" htmlFor="sex">
                                Sex <span className="invalid-form">*</span>
                              </label>
                              <Field
                                as="select"
                                className="select2 form-select"
                                name="sex"
                              >
                                <option value="">Select</option>
                                {sexOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="sex"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6 col-12">
                              <label
                                htmlFor="country_id"
                                className="form-label"
                              >
                                Country <span className="invalid-form">*</span>
                              </label>
                              <Field
                                as="select"
                                className="select2 form-select"
                                name="country_id"
                              >
                                <option value="">Select</option>
                                {countries?.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="country_name"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <label htmlFor="phone_no" className="form-label">
                                Phone No <span className="invalid-form">*</span>
                              </label>
                              <Field
                                type="tel"
                                className={`form-control`}
                                name="phone_no"
                                placeholder="Enter your  Phone No"
                                autofocus
                                maxLength="10"
                                onChange={(e) => {
                                  if (/^[0-9]*$/.test(e.target.value)) {
                                    setFieldValue("phone_no", e.target.value);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="phone_no"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6 col-12">
                              <label htmlFor="password" className="form-label">
                                Password <span className="invalid-form">*</span>
                              </label>
                              <div className="input-group input-group-merge">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  className={`form-control`}
                                  name="password"
                                  placeholder="Password"
                                  autofocus
                                />
                                <span
                                  className="input-group-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>

                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <label
                                htmlFor="password_confirmation"
                                className="form-label"
                              >
                                Password Confirmation
                                <span className="invalid-form">*</span>
                              </label>
                              <div className="input-group input-group-merge">
                                <Field
                                  type={showPassword2 ? "text" : "password"}
                                  className={`form-control`}
                                  name="password_confirmation"
                                  placeholder="Password Confirmation"
                                  autofocus
                                />
                                <span
                                  className="input-group-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setShowPassword2(!showPassword2)}
                                >
                                  <FontAwesomeIcon
                                    icon={showPassword2 ? faEyeSlash : faEye}
                                  />
                                </span>
                              </div>

                              <ErrorMessage
                                name="password_confirmation"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                          </div>
                          <button
                            className="btn btn-primary d-grid w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
