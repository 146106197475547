
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.jpg";
import api from "services/api";
import { useToaster } from "hooks";


export const ResetPassword = () => {
  // const navigate = useNavigate();
  const { setSuccessToaster, setErrorToaster } = useToaster();

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    // agree: Yup.boolean()
    //   .oneOf([true], "Must agree to terms and conditions")
    //   .required("Required"),
  });

  const handleSubmit = async (values) => {
    console.log("Submitting values:", values); // For testing purposes
    try {
      const response = await api.post("/reset-password" , { email: values.email })
      console.log("response:",response.data);
      setSuccessToaster("email registered")
    } catch (error) { 
      console.log("error:",error)
      setErrorToaster("email is not registered")
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4" style={{ maxWidth: "500px", width: "100%" }}>
        <div className="text-center">
          <img src={Logo} width="80px" alt="Logo" />
        </div>
        <div className="text-center mt-3">
          <h2>Reset Password</h2>
        </div>
        <Formik
          initialValues={{ email: "", agree: false }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors,}) => (
       
            <Form>
              <div className="mb-3 position-relative">
                <label for="email" className="form-label">Email Address</label>
                <Field
                  name="email"
                  type="email"
                  className="form-control"
                  id="email"
                />
                <ErrorMessage name="email" component="div" className="text-danger" />
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={isSubmitting}
              >
                Submit
              </button>
              {errors.email && (
                <div className="mt-3 text-danger text-center">{errors.email}</div>
              )}
            </Form>
          )}
        </Formik>
        <div className="text-center mt-3">
          <p>
            I remembered my password. <Link to="/">Back to Login</Link>
          </p>
        </div>
        <div className="text-center">
          <p>
            Don't have a Zenosure account yet? <Link to="/register">Register Now</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
