import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import ProfileDetailes from "./slices/profile_slice";

const rootReducer = combineReducers({
  profile: ProfileDetailes,
});

const store = configureStore({
  reducer: rootReducer, // Pass the rootReducer
});

export default store;
