import { Container } from "components";
import { useToaster } from "hooks";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import api from "services/api";
import { Privacy_Terms } from "services/api.config";

export const PartnerWihUs = () => {
  const [parterWithUs , setParterWithUs ] = useState("");
  const {setSuccessToaster,setErrorToaster} = useToaster();

  const fetchPartnerWithUs = async () => {
    try {
      const response = await api.get(`/${Privacy_Terms}`);
      const partnerDetails = response?.data?.data?.siteSettings;
      setParterWithUs(partnerDetails?.partner_with_us || "")
      console.log("fetchPartnerWithUs:",partnerDetails);
      
    } catch (error) {
      console.log("fetchPartnerWithUs error:",error)
    }
  }
useEffect( () => {
  fetchPartnerWithUs()
},[])
  const handleSubmit = async () => {

    const details = {
      partner_with_us: parterWithUs
    }
    try {
      const response = await api.post(`/${Privacy_Terms}`,details);
      console.log("handleSubmit:",response)
      setSuccessToaster(response?.data?.message)
    } catch (error) {

      console.log("handleSubmit error:", error)
    }
  }

  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="card-header mb-3">Partner with us</h4>
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <JoditEditor 
                  value={parterWithUs}
                  onChange={(newContent) => setParterWithUs(newContent)}
                />

                <button
                  type="submit"
                  className="btn btn-primary mt-3"
                    onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
