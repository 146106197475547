import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToaster } from "hooks";

export const Toaster = () => {
  const { toaster } = useSelector((state) => state?.profile);
  const { closeToaster } = useToaster();

  useEffect(()=>{
    if(toaster.open){
      setTimeout(()=>{
        closeToaster()
      },2000)
    }
  },[toaster])

  const toastStyles = toaster.type === "success" ? "bg-success text-white" : toaster.type === "error" ? "bg-danger text-white" : "" ;
  return (
    <>
      {toaster.open && (
        <div
          class={`bs-toast toast toast-ex animate__animated my-2 fade animate__tada show ${toastStyles}`}
          data-bs-delay="2000"
        >
          <div class="toast-header">
            <i class="ti ti-bell ti-xs me-2"></i>
            <div class="me-auto fw-medium">{toaster.type === "success" ? "Success" :toaster.type === "error" ? "Error":"Notification"}</div>
          
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={closeToaster}
            ></button>
          </div>
          <div class="toast-body">{toaster.message}</div>
        </div>
      )}
    </>
  );
};
