import { Container } from "components";
import { useToaster } from "hooks";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import api from "services/api";
import { Privacy_Terms } from "services/api.config";

export const TermsConditions = () => {
  const [termsCondition , setTermsCondition ] = useState("");
  const {setSuccessToaster , setErrorToaster } = useToaster();


  const fetchTermsCondition = async () => {
    try {
      const response = await api.get(`/${Privacy_Terms}`);
      const TermsConditon = response?.data?.data?.siteSettings;
      
      setTermsCondition(TermsConditon?.terms_conditions|| ""); // Initialize editor content
      
      console.log("fetchTermsCondition:", TermsConditon);
    } catch (error) {
      console.log("fetchPrivacyPolicy Error:", error);
    }
  };
  
  useEffect( () => {
    fetchTermsCondition();
  },[])
  const handleSubmitTermsCond = async () => {
    try {
      const response = await api.post(`/${Privacy_Terms}`,{
        terms_conditions: termsCondition
      });
      setSuccessToaster("Terms and Conditons Succesfully Updateded ")
      console.log("TermsConditon Success:",response);
    } catch (error) {
      let message = error?.response?.data?.message ;
      setErrorToaster(message);
      console.log("TermsConditon Error:",message);
    }
  }
  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="card-header mb-3">Terms conditions</h4>

          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <JoditEditor 
                  value={termsCondition}
                  onChange={(newContent) => setTermsCondition(newContent)}
                />
                <button type="submit"
                className="btn btn-primary mt-3"
                onClick={handleSubmitTermsCond}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
