import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import api from "services/api";
import { useAuth } from "hooks";
import { Container } from "components";
import AccountMenu from "components/accountMenu";
import PlansList from "./components/plansList";
import MembershipSelection from "./components/membershipSelection";
import ApplicationDetails from "./components/applicationDetails";
import BillingDetails from "./components/billingDetails";

const BillingPlans = () => {
  const [activeMembership, setActiveMembership] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [membershipId, setMembershipId] = useState("");
  const params = useParams();

  useEffect(() => {
    fetchMembership();
  }, [params?.id]);

  const fetchMembership = () => {
    api.get(`/membership-plans/${params?.id}`).then((res) => {
      if (res?.data?.data?.membershipPlans) {
        setActiveMembership(res?.data?.data?.membershipPlans);
      }
    });
  };

  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <div class="row">
            {/* <AccountMenu /> */}

            {activeStep === 0 && (
              <MembershipSelection
                setActiveMembership={setActiveMembership}
                activeMembership={activeMembership}
                setActiveStep={setActiveStep}
              />
            )}

            {activeStep === 1 && (
              <ApplicationDetails
                setActiveStep={setActiveStep}
                activeMembership={activeMembership}
                setMembershipId={setMembershipId}
              />
            )}

            {activeStep === 2 && (
              <BillingDetails
                setActiveStep={setActiveStep}
                activeMembership={activeMembership}
                membershipId={membershipId}
              />
            )}

            {/* {activeStep === 3 && (
              <RequestRecieved setActiveStep={setActiveStep} />
            )} */}
          </div>
        </div>
        {/* <Footer /> */}
        <div class="content-backdrop fade"></div>
      </div>
    </Container>
  );
};

export default BillingPlans;
