import React from "react";
import { Sidenav } from "./sidenav";
import { Header } from "./header";

export const Container = ({ children }) => {
  return (
    <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
        <Sidenav />
        <div class="layout-page">
          <Header />
          <div class="content-wrapper">{children}</div>
        </div>
      </div>
      <div class="layout-overlay layout-menu-toggle"></div>
      <div class="drag-target"></div>
    </div>
  );
};
