import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

const ApplicationForm = ({
  setFieldValue,
  relations,
  countries,
  addMemBeship,
  values,
  setValues,
  isLast,
  i,
  mem,
  handleClick,
}) => {
  return (
    <div class="card mb-4 mx-4">
      <h5 class="card-header">
        Please Fill In Your Member Application Details
      </h5>

      <div class="card-body">
        <div class="d-flex align-items-start align-items-sm-center gap-4">
          <img
            src={mem.imgpath || "../../assets/img/avatars/14.png"}
            alt="user-avatar"
            class="d-block w-px-100 h-px-100 rounded"
            id="uploadedAvatar"
          />
          <div class="button-wrapper">
            <label
              for="upload"
              class="btn btn-primary me-2 mb-3"
              tabindex="0"
              onClick={() => handleClick(i)}
            >
              <span class="d-none d-sm-block">Upload new photo</span>
            </label>
            <button
              type="button"
              class="btn btn-label-secondary account-image-reset mb-3"
            >
              <i class="ti ti-refresh-dot d-block d-sm-none"></i>
              <span class="d-none d-sm-block">Reset</span>
            </button>

            <div class="text-muted">
              Allowed JPG, GIF or PNG. Max size of 800K
            </div>
          </div>
        </div>
      </div>
      <hr class="my-0" />
      <div class="card-body">
        <div className="row mb-3">
          <div className="col-md-6 col-12">
            <label for="username" className="form-label">
              First Name <span className="invalid-form">*</span>
            </label>
            <Field
              type="text"
              className={`form-control`}
              name={`members.${i}.first_name`}
              placeholder="First Name"
              autofocus
            />

            <ErrorMessage
              name={`members.${i}.first_name`}
              component="div"
              className="invalid-form"
            />
          </div>
          <div className="col-md-6 col-12">
            <label for="last_name" className="form-label">
              Last Name <span className="invalid-form">*</span>
            </label>

            <Field
              type="text"
              className={`form-control `}
              name={`members.${i}.last_name`}
              placeholder="First Name"
              autofocus
            />

            <ErrorMessage
              name={`members.${i}.last_name`}
              component="div"
              className="invalid-form"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6 col-12">
            <label for="email" className="form-label">
              Email <span className="invalid-form">*</span>
            </label>
            <Field
              type="text"
              className={`form-control`}
              name={`members.${i}.email`}
              placeholder="Enter your Email "
              autofocus
            />

            <ErrorMessage
              name={`members.${i}.email`}
              component="div"
              className="invalid-form"
            />
          </div>

          <div className="col-md-6 col-12">
            <label for="phone_no" className="form-label">
              Phone No <span className="invalid-form">*</span>
            </label>
            <Field
              type="text"
              className={`form-control`}
              name={`members.${i}.phone_no`}
              placeholder="Enter your  Phone No"
              autofocus
            />
            <ErrorMessage
              name={`members.${i}.phone_no`}
              component="div"
              className="invalid-form"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6 col-12">
            <label for="dob" className="form-label">
              DOB <span className="invalid-form">*</span>
            </label>
            <Field
              type="date"
              className={`form-control`}
              name={`members.${i}.dob`}
              placeholder=""
              autofocus
            />
            <ErrorMessage
              name={`members.${i}.dob`}
              component="div"
              className="invalid-form"
            />
          </div>
          <div className="col-md-6 col-12">
            <label className="form-label" for="country">
              Sex <span className="invalid-form">*</span>
            </label>
            <Field
              as="select"
              className="select2 form-select"
              name={`members.${i}.sex`}
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Field>

            <ErrorMessage
              name={`members.${i}.sex`}
              component="div"
              className="invalid-form"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6 col-12">
            <label for="nationality" className="form-label">
              Nationality <span className="invalid-form">*</span>
            </label>
            <Field
              as="select"
              className="select2 form-select"
              name={`members.${i}.nationality`}
            >
              <option value="">Select</option>
              {countries?.map((country) => {
                return <option value={country?.id}>{country?.name}</option>;
              })}
            </Field>
            <ErrorMessage
              name={`members.${i}.nationality`}
              component="div"
              className="invalid-form"
            />
          </div>
          <div className="col-md-6 col-12">
            <label className="form-label" for="relation">
              Relation <span className="invalid-form">*</span>
            </label>
            <Field
              as="select"
              className="select2 form-select"
              name={`members.${i}.relation`}
            >
              <option value="">Select</option>
              {relations.map((relation, index) => (
                <option key={index} value={relation}>
                  {relation}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name={`members.${i}.relation`}
              component="div"
              className="invalid-form"
            />
          </div>
        </div>

        {isLast && (
          <button
            className="btn btn-transparent"
            type="button"
            style={{ color: "#7367F0" }}
            onClick={() => addMemBeship(values, setValues)}
          >
            + Add Member
          </button>
        )}
      </div>
    </div>
  );
};

export default ApplicationForm;
