// src/features/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  countries: [],
  industries: [],
  roles: [],
  areas: [],
  toaster: {
    open: false,
    message: "",
    type: "",
  },
};

const ProfileDetailes = createSlice({
  name: "ProfileDetailes",
  initialState,
  reducers: {
    addCountries: (state, action) => {
      state.countries = action.payload;
    },
    addIndustries: (state, action) => {
      state.industries = action.payload;
    },
    addRoles: (state, action) => {
      state.roles = action.payload;
    },
    addAreas: (state, action) => {
      state.areas = action.payload;
    },
    setToaster: (state, action) => {
      state.toaster = action.payload;
    },
  },
});

export const { addCountries, addIndustries, addRoles, addAreas, setToaster } = ProfileDetailes.actions;

export default ProfileDetailes.reducer;
