import { Container } from "components";
import { useToaster } from "hooks";
import JoditEditor from "jodit-react";
import React, { useEffect, useState } from "react";
import api from "services/api";
import { Privacy_Terms } from "services/api.config";

export const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState("");
  const { setSuccessToaster, setErrorToaster } = useToaster();

  const fetchAboutUs = async () => {
    try {
      const response = await api.get(`/${Privacy_Terms}`);
      const TermsConditon = response?.data?.data?.siteSettings;

      setAboutUs(TermsConditon?.about_us || ""); // Initialize editor content

      console.log("fetchAboutUs:", TermsConditon);
    } catch (error) {
      console.log("fetchAboutUs Error:", error);
    }
  };
  useEffect(() => {
    fetchAboutUs();
  }, []);
  const handleSubmit = async () => {
    const data = {
      about_us: aboutUs,
    };

    try {
      const response = await api.post(`/${Privacy_Terms}`, data);
      console.log("AboutUs:", response);
      setSuccessToaster("AboutUs Succesfully Updated");
    } catch (error) {
      let message = error?.response?.data?.message;
      setErrorToaster(message);
      console.log("AboutUs Error:", message);
    }
  };
  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="card-header mb-3">About us</h4>

          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <JoditEditor
                  value={aboutUs}
                  onChange={(newContent) => setAboutUs(newContent)}
                />
                <button
                  type="submit"
                  className="btn btn-primary mt-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
