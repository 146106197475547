import {
  faDeleteLeft,
  faEye,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "components";
import Pagination from "components/pagination";
import { useToaster } from "hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "services/api";

export const ShowPartners = () => {
  const [fetchPartners, setFetchPartners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [seletedPatnerId, setSelectedPatnerId] = useState(null);
  const {setErrorToaster,setSuccessToaster} = useToaster();
  const fetchPart = async (page = 1) => {
    try {
      const response = await api.get(`/partners?page=${page}`);
      setFetchPartners(response?.data?.data);
      setCurrentPage(page);
      console.log("fetchPartners list :", response?.data?.data);
    } catch (error) {
      console.log("fetchPartners errors :", error);
    }
  };

  useEffect(() => {
    fetchPart(1);
  }, []);
  const openDeleteModal = (id) => {
    setSelectedPatnerId(id);
    setDeleteModal(true);
  };
  const handleDeleteModelConfirmation =async () => {
    if(seletedPatnerId) {
      try {
        const response = await api.delete(`/partners/${seletedPatnerId}`);
        console.log("handleDeleteModelConfirmation:",response);
        setDeleteModal(false)
        fetchPart();
        setSuccessToaster("Successfully deleted patner")
      } catch (error) {
        console.log("handleDeleteModelConfirmation Error:",error);
        setErrorToaster("Patner not deleted")
      }
    }
    
  }
  return (
    <Container>
      <div className="content-wrapper">
        <div
          className="container-xxl flex-grow-1 container-p-y"
          style={{ height: "43px" }}
        >
          <h4 className="card-header">Partners</h4>
          <div className="d-flex justify-content-end">
            <input
              type="text"
              placeholder="search"
              className=" me-3 mb-4 "
              style={{
                outline: "none",
                boxShadow: "none",
                border: "1px solid transparent",
              }}
            />
            <button className="btn btn-primary me-2 mb-4">Search</button>
            <button
              className="btn btn-primary me-5 mb-4"
              onClick={() => navigate("/addPartner")}
            >
              Add Partner
            </button>
          </div>
          <div className="card-datatable table-responsive">
            <table className="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Name</th>
                  <th>Contact person</th>
                  <th>Contact</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Contract start</th>
                  <th>Contract end</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {fetchPartners?.membershipPlans?.map((res, index) => {
                  const serialNumber =
                    (currentPage - 1) * fetchPartners?.pagination?.per_page +
                    (index + 1);
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{res?.name}</td>
                      <td>{res?.contact_person}</td>
                      <td>{res?.contact_info}</td>
                      <td>{res?.type}</td>
                      <td>{res?.price}</td>
                      <td>
                        {moment(res?.contract_start_date).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {moment(res?.contract_end_date).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faEye}
                          className="me-3"
                          onClick={() =>
                            navigate(`/partnersDetails/${res?.partner_id}`)
                          }
                        />
                        <FontAwesomeIcon
                          title="Edit"
                          icon={faPencil}
                          className="me-3"
                          onClick={() =>
                            navigate(`/editPartner/${res?.partner_id}`)
                          }
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={(e) => {
                            openDeleteModal(res?.partner_id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {fetchPartners?.pagination?.total > 0 && <Pagination totalPages={(fetchPartners?.pagination?.total)} perPage={fetchPartners?.pagination?.per_page} handleFunction={fetchPart} />

}
          </div>
        </div>
      </div>
      {deleteModal && (
        <div className="modal show d-block" tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Are you sure you want to delete this partner?
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setDeleteModal(false)}
                >
                </button>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" onClick={() => setDeleteModal(false)}>Cancel</button>
                <button className="btn btn-danger" onClick={handleDeleteModelConfirmation}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};
