import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import {
  addCountries,
  addIndustries,
  addRoles,
  addAreas,
} from "../../../redux/slices/profile_slice";
import { useAuth, useToaster } from "hooks";
import AccountMenu from "components/accountMenu";

const initialValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  email: "",
  phone_no: "",
  dob: "",
  address: "",
  city: "",
  postal_code: "",
  sex: "",
  state: "",
  country_id: null, // Initialize country as null or any default value
  position: "",
  occupation_role_id: null,
  company: "",
  industry_id: null,
  function_id: null,
  country_name: "",
  account_type: "",
};

function EditProfile() {
  const [profileImage, setProfileImage] = useState("");
  const [profileData, setProfileData] = useState({ ...initialValues });
  const { user, updateUserDetails } = useAuth();
  const isLoggedIn = localStorage.getItem("user");
  const navigate = useNavigate();
  const { setSuccessToaster, setErrorToaster } = useToaster();
  const dispatch = useDispatch();
  const { countries, industries, roles, areas } = useSelector(
    (state) => state?.profile
  );

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const sexOptions = ["Male", "Female", "Other"]; // Options for the sex field

  const validationSchema = Yup.object().shape({
    // company: Yup.string().required("Company is required"),
    // position: Yup.string().required("Position is required"),
    user_id: Yup.number().typeError("User ID must be a number"),
    country_id: Yup.number().typeError("Country ID must be a number"),
    industry_id: Yup.number().typeError("Industry ID must be a number"),
    function_id: Yup.number().typeError("Function ID must be a number"),
    dob: Yup.string().required("DOB IS Required"),
    city: Yup.string()
      .required("City is required")
      .min(3, "City must be at least 3 characters"),
    state: Yup.string()
      .required("State is required")
      .min(3, "State must be at least 3 characters"),
    postal_code: Yup.string()
      .matches(/^\d{3,6}$/, "Postal code must be between 3 and 6 digits")
      .required("Postal code is required"),
    address: Yup.string()
      .required("Address is required")
      .min(10, "Address must be at least 10 characters"),
    first_name: Yup.string()
      .required("First name is required")
      .min(3, "First name must be at least 3 characters"),
    last_name: Yup.string()
      .required("Last name is required")
      .min(3, "Last name must be at least 3 characters"),
    middle_name: Yup.string()
      .required("Middle name is required")
      .min(3, "middle name must be at least 3 characters"),
  });

  const handleProfileData = (data) => {
    const { first_name, last_name, email, profile, middle_name } = data;
    const updateData = {
      first_name,
      last_name,
      middle_name,
      email,
      phone_no: profile?.phone_no,
      dob: profile?.dob,
      sex: profile?.sex,
      account_type: profile?.account_type || "",
      country_name: profile?.country_name,
      address: profile?.address,
      city: profile?.city,
      postal_code: profile?.postal_code,
      state: profile?.state,
      country_id: profile?.country_id, // Initialize country as null or any default value
      position: profile?.position,
      occupation_role_id: profile?.occupation_role_id || null,
      company: profile?.company,
      industry_id: profile?.industry_id,
      function_id: profile?.function_id,
    };
    setProfileImage(profile?.profile_pic_url);
    setProfileData(updateData);
    updateUserDetails("profile_pic_url", profile?.profile_pic_url);
  };

  const fetchUserDetails = async () => {
    try {
      const response = await api.get(`/users/${user?.user_id}`);

      const data = response?.data?.data?.user;
      if (data) {
        handleProfileData(data);
        console.log("getfetchUserDetails:", data);
      }
    } catch (error) {
      console.log("fetchUserDetails editProfile Error :", error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      let payload = {
        ...values,
        dob: moment(values.dob).format("DD-MM-YYYY"),
      };
      // delete payload.email;
      console.log("Payload before API call:", payload);

      const response = await api.post("/profile", payload);
      console.log("API Response :", response);

      fetchUserDetails();
      setSuccessToaster("Profile Updated Successfully");
      navigate("/profile");
    } catch (err) {
      let message = err?.response?.data?.message || "Failed To Update Data";
      setErrorToaster(message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (countries?.length === 0) {
          await api.get("/countries").then((res) => {
            let countriesList = res?.data?.data?.countries || [];
            if (countriesList?.length > 0) {
              dispatch(addCountries(countriesList));
            }
          });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    fetchUserDetails();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await api.post("/profile-picture", formData);
      // console.log("Upload Image Response:", response);

      if (response) {
        fetchUserDetails();
        setSuccessToaster("Profile Pic Updated Successfully");
      } else {
        setErrorToaster("Failed To Update Data");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div class="container-xxl flex-grow-1 container-p-y">
      <div class="row">
        <div class="col-md-12">
          {/* <AccountMenu /> */}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }} // Hide the input visually
            ref={fileInputRef} // Ref to access the input element
          />
          <Formik
            initialValues={profileData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, dirty, errors }) => (
              <Form>
                <div class="card mb-4">
                  <h5 class="card-header">Profile Details</h5>

                  <div class="card-body">
                    <div class="d-flex align-items-start align-items-sm-center gap-4">
                      <img
                        src={profileImage}
                        alt="user-avatar"
                        class="d-block w-px-100 h-px-100 rounded"
                        id="uploadedAvatar"
                      />
                      <div class="button-wrapper">
                        <label
                          for="upload"
                          class="btn btn-primary me-2 mb-3"
                          tabindex="0"
                          onClick={handleClick}
                        >
                          <span class="d-none d-sm-block">
                            Upload new photo
                          </span>
                        </label>

                        <div class="text-muted">
                          Allowed JPG, GIF or PNG. Max size of 800K
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-0" />
                  <div class="card-body">
                    <div className="row mb-3">
                      <div className="col-md-4 col-12">
                        <label for="username" className="form-label">
                          First Name <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="first_name"
                          placeholder="First Name"
                          autofocus
                        />

                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                      <div className="col-md-4 col-12">
                        <label for="username" className="form-label">
                          Middle Name
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="middle_name"
                          placeholder="Middle Name"
                          autofocus
                        />

                        <ErrorMessage
                          name="middle_name"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                      <div className="col-md-4 col-12">
                        <label for="last_name" className="form-label">
                          Last Name <span className="invalid-form">*</span>
                        </label>

                        <Field
                          type="text"
                          className={`form-control `}
                          name="last_name"
                          placeholder="Last Name"
                          autofocus
                        />

                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6 col-12">
                        <label for="email" className="form-label">
                          Email <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="email"
                          placeholder="Enter your Email "
                          autofocus
                          disabled
                        />

                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-form"
                        />
                      </div>

                      <div className="col-md-6 col-12">
                        <label htmlFor="phone_no" className="form-label">
                          Phone No <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="phone_no"
                          placeholder="Enter your  Phone No"
                          autofocus
                        />
                        <ErrorMessage
                          name="phone_no"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6 col-12">
                        <label htmlFor="dob" className="form-label">
                          DOB <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="date"
                          className={`form-control`}
                          name="dob"
                          placeholder=""
                          format="dd-mm-yyyy"
                          autofocus
                        />
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label className="form-label" htmlFor="sex">
                          Sex <span className="invalid-form">*</span>
                        </label>
                        <Field
                          as="select"
                          className="select2 form-select"
                          name="sex"
                        >
                          <option value="">Select</option>
                          {sexOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="sex"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6 col-12">
                        <label htmlFor="country_id" className="form-label">
                          Nationality <span className="invalid-form">*</span>
                        </label>
                        <Field
                          as="select"
                          className="select2 form-select"
                          name="country_id"
                        >
                          <option value="">Select</option>
                          {countries.map((country) => (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="country_name"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label className="form-label" htmlFor="account_type">
                          Account Type <span className="invalid-form">*</span>
                        </label>
                        <Field
                          as="select"
                          className="select2 form-select"
                          name="account_type"
                          disabled
                        >
                          <option value="">Select</option>
                          <option value="Personal">Personal</option>
                          <option value="Corporate">Corporate</option>
                        </Field>
                        <ErrorMessage
                          name="function_id"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                    </div>
                    {/* <div className="row mb-3">
                      <div className="col-md-6 col-12">
                        <label htmlFor="company" className="form-label">
                          Company <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="company"
                          placeholder="Company"
                          autofocus
                        />
                        <ErrorMessage
                          name="company"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label htmlFor="position" className="form-label">
                          Position <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="position"
                          placeholder="Position"
                          autofocus
                        />
                        <ErrorMessage
                          name="position"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                    </div> */}
                    <div className="row mb-3">
                      <div className="col-md-6 col-12">
                        <label htmlFor="address" className="form-label">
                          Address <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="address"
                          placeholder="Address"
                          autofocus
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label htmlFor="city" className="form-label">
                          City <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="city"
                          placeholder="City"
                          autofocus
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6 col-12">
                        <label htmlFor="postal_code" className="form-label">
                          Postal Code <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="postal_code"
                          placeholder="Postal_code"
                          autofocus
                        />
                        <ErrorMessage
                          name="postal_code"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label htmlFor="state" className="form-label">
                          state <span className="invalid-form">*</span>
                        </label>
                        <Field
                          type="text"
                          className={`form-control`}
                          name="state"
                          placeholder="State"
                          autofocus
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="invalid-form"
                        />
                      </div>
                    </div>

                    {values?.account_type === "Corporate" && (
                      <>
                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label for="username" className="form-label">
                              Company Name{" "}
                              <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="company_name"
                              placeholder="Company Name"
                              autofocus
                            />
                            <ErrorMessage
                              name="company_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label for="email" className="form-label">
                              Company Sector{" "}
                              <span className="invalid-form">*</span>
                            </label>

                            <Field
                              type="text"
                              className={`form-control `}
                              name="last_name"
                              placeholder="Company Sector"
                              autofocus
                            />

                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className=" col-12">
                            <label for="username" className="form-label">
                              Company Address{" "}
                              <span className="invalid-form">*</span>
                            </label>
                            <Field
                              type="text"
                              className={`form-control`}
                              name="company_address"
                              placeholder="Company Address"
                              autofocus
                            />

                            <ErrorMessage
                              name="company_address"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <button
                      className="btn btn-primary d-grid w-100"
                      type="submit"
                    >
                      Modify
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
