import React from "react";

import { Container } from "components";
import Footer from "components/footer";

export const Home = () => {
  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">

        </div>
        {/* <Footer /> */}
        <div class="content-backdrop fade"></div>
      </div>
    </Container>
  );
};
