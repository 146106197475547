import { Container } from "components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "services/api";
import QRCode from "qrcode.react";
import Footer from "components/footer";
import moment from "moment";

export const MembershipDetailsCard = () => {
  const params = useParams();
  const [memberships, setMemberships] = useState([]);

  const fetchMemberShipCardDetails = async () => {
    try {
      const response = await api.get(
        `/membership-application?applicationNo=${params.id}`
      );
      setMemberships(response?.data?.data?.memberApplication);
      console.log(
        "fetchMemberShipCardDetails :",
        response?.data?.data?.memberApplication
      );
    } catch (error) {
      console.log("fetchMemberShipCardDetails Error:", error);
    }
  };
  useEffect(() => {
    fetchMemberShipCardDetails();
  }, []);
  return (
    <Container>
      <div class="content-wrapper">
        <div
          class="container-xxl flex-grow-1 container-p-y"
          style={{ height: "43px" }}
        >
          <ul className="nav nav-pills flex-column flex-md-row mb-4">
            <li className="nav-item">
              <h4 className={`py-2 mb-2`}>Memberships Details</h4>
            </li>
          </ul>
          <div class="card mb-4">
            {memberships.map((membershipPersonData, index) => {
              return (
                <div class="card-body" key={index}>
                  <div class="row">
                    <div class="col-md-5 mb-1 ">
                      <div class="customer-avatar-section">
                        <img
                          class="img-fluid rounded my-3"
                          src={
                            membershipPersonData.member_pic_url ||
                            "../../assets/img/avatars/14.jpg"
                          }
                          style={{ width: "120px", height: "120px" }}
                          alt="User avatar"
                        />
                        <div class="customer-info ">
                          <h4 class="mb-1">
                            {membershipPersonData.first_name}{" "}
                            {membershipPersonData.middle_name}{" "}
                            {membershipPersonData.last_name}
                          </h4>
                        </div>
                      </div>
                      <div class="info-container">
                        <ul class="list-unstyled mt-3">
                          <li class="mb-2">
                            <span class="fw-medium me-2">Email:</span>
                            <span>{membershipPersonData?.email}</span>
                          </li>
                          <li class="mb-2">
                            <span class="fw-medium me-2">DOB:</span>
                            <span>{moment(membershipPersonData.dob).format("DD-MM-YYYY")}</span>
                          </li>
                          <li class="mb-2">
                            <span class="fw-medium me-2">Status:</span>
                            <span
                              className={`badge ${
                                membershipPersonData.status === "Pending"
                                  ? "bg-warning"
                                  : membershipPersonData.status === "Approved"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {membershipPersonData.status}
                            </span>
                          </li>
                          <li class="mb-2">
                            <span class="fw-medium me-2">Contact:</span>
                            <span> {membershipPersonData.phone_no}</span>
                          </li>
                          <li class="mb-2">
                            <span class="fw-medium me-2">Kyc Status:</span>
                            <span
                              className={`badge ${
                                membershipPersonData.kyc_details.status ===
                                "Pending"
                                  ? "bg-warning"
                                  : membershipPersonData.kyc_details.status ===
                                    "Approved"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {membershipPersonData.kyc_details.status}
                            </span>
                          </li>

                          <li class="mb-2">
                            <span class="fw-medium me-2">Country:</span>
                            <span>{membershipPersonData.country}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-7 mb-1 ">
                      <div className="d-flex align-items-end">
                        <div
                          className="col-md-9"
                          style={{ paddingTop: "13px" }}
                        >
                          <QRCode
                            value={`https://zenosure.itprior.com/checkMembership?membershipid=${membershipPersonData.application_no}`}
                            size={150}
                          />
                          <div className="text-left">
                            <div class="mb-3">
                              <h6 class="mb-1">Your Current Plan is Basic </h6>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    membershipPersonData.membership_plan.title,
                                }}
                              />
                            </div>
                            <div class="mb-3">
                              <h6 class="mb-1">
                                Active until {moment(membershipPersonData.end_date).format("DD-MM-YYYY")}
                              </h6>
                              <p>
                                We will send you a notification upon
                                Subscription expiration
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="card-body pb-0 px-0 px-md-4 col-md-3">
                          <img
                            src="../../assets/img/illustrations/card-advance-sale.png"
                            height="140"
                            alt="view sales"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
        <div class="content-backdrop fade"></div>
      </div>
    </Container>
  );
};
