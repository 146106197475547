import React from "react";
import { Container } from "components";
import { useParams, useNavigate } from "react-router-dom";

const PaymentResult = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <h1 className="text-center">
            {params?.id === "success" && "Payments Done Successfully"}
            {params?.id === "failure" && "Payment Failed ,Please Try Again"}
          </h1>
          <div className="text-center w-100">
            <button
              className="btn btn-primary"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Go To Home
            </button>
          </div>
          <div class="content-backdrop fade"></div>
        </div>
      </div>
    </Container>
  );
};

export default PaymentResult;
